import Div from '@hellodarwin/core/lib/components/common/div';
import styled from '@hellodarwin/core/lib/plugins/styled';

export const CategoryContainer = styled(Div)<{ $selected?: boolean }>`
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.beige_2};

  padding: 1.5rem 1rem;
  cursor: pointer;
  border-radius: 0;
  width: 180px;
  height: 172px;
  position: relative;
  transition: $transition;
  border-radius: 4px;

  .category-icon-container {
    font-size: 32px;
    background-color: ${(props) => props.theme.colors.beige_3};
    border: 1px solid ${(props) => props.theme.colors.primary};

    border-radius: 48px;
    color: ${(props) => props.theme.colors.grey_1};
    width: fit-content;
    padding: 1rem;

    svg {
      fill: ${(props) => props.theme.colors.primary};
    }
  }

  &:hover,
  &.selected {
    background-color: ${(props) => props.theme.colors.purple_5};
    border: 1px solid ${(props) => props.theme.colors.purple_2};

    .category-icon-container {
      background-color: ${(props) => props.theme.colors.purple_2};
      border: 1px solid ${(props) => props.theme.colors.purple_2};

      svg {
        fill: ${(props) => props.theme.colors.white_1};
      }
    }
  }

  .category-container-saved-label {
    display: ${(props) => (props.$selected ? 'block' : 'none')};

    color: ${(props) => props.theme.colors.green_1};

    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }
`;
