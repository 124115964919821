import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { Rfp } from '@hellodarwin/core/lib/features/entities';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import theme from '@hellodarwin/core/lib/theme';
import { IconProps } from '@hellodarwin/icons/dist/features/entities/general';
import Confirm from '@hellodarwin/icons/dist/icons/Confirm';
import Grants from '@hellodarwin/icons/dist/icons/Grants';
import ProjectsList from '@hellodarwin/icons/dist/icons/ProjectsList';
import WaitingMoney from '@hellodarwin/icons/dist/icons/WaitingMoney';
import { ReactNode } from 'react';
import { RfpSummaryContainer, RfpSummaryScroll } from './styles';

type RfpSummaryProps = {
  rfps: Rfp[];
};

const RfpSummary = ({ rfps }: RfpSummaryProps) => {
  const { t } = useTranslation();

  const rfpCompletedTotal = () => {
    var completedTotalRfp = 0;
    rfps &&
      rfps.forEach((rfp) => {
        if (rfp.status === 'Completed') {
          completedTotalRfp++;
        }
      });
    return completedTotalRfp;
  };

  const rfpTotalEarned = () => {
    var totalEarnedMoney = 0;
    rfps &&
      rfps.forEach((rfp) => {
        if (rfp.status === 'Completed') {
          totalEarnedMoney += Number(rfp.lead_price);
        }
      });
    return totalEarnedMoney;
  };

  const rfpPendingEarned = () => {
    var totalPendingMoney = 0;
    rfps &&
      rfps.forEach((rfp) => {
        if (rfp.status === 'Active') {
          totalPendingMoney += Number(rfp.lead_price);
        }
      });
    return totalPendingMoney;
  };

  return (
    <RfpSummaryScroll>
      <RfpSummaryContainer>
        <SummaryBox
          Icon={ProjectsList}
          value={`${rfps.length}`}
          title={t('referral|referralSummary.totalProjects')}
        />
        <SummaryBox
          Icon={Confirm}
          value={`${rfpCompletedTotal()}`}
          title={t('referral|referralSummary.completedProjects')}
        />
        <SummaryBox
          Icon={Grants}
          value={`${rfpTotalEarned()} $`}
          title={t('referral|referralSummary.totalEarned')}
        />
        <SummaryBox
          Icon={WaitingMoney}
          value={`${rfpPendingEarned()} $`}
          title={t('referral|myReferral.pendingRevenue')}
        />
      </RfpSummaryContainer>
    </RfpSummaryScroll>
  );
};

const SummaryBox = ({
  Icon,
  value,
  title,
}: {
  Icon: (props: IconProps) => ReactNode;
  value: string;
  title: string;
}) => (
  <Div
    style={{ padding: 16, width: 250, height: 150, flexShrink: 0 }}
    justify="space-between"
    flex={'column'}
    gap={8}
    borderColor={theme.colors.primary}
    backgroundColor={theme.colors.white_1}
  >
    <Div flex={'column'} gap={16} align="center">
      <Icon width={32} height={32} style={{ color: theme.colors.primary }} />
      <Typography textAlign="center">{title}</Typography>
    </Div>

    <Typography elementTheme="h6" textAlign="center">
      {value}
    </Typography>
  </Div>
);

export default RfpSummary;
