import { IconProps } from '@hellodarwin/icons/dist/features/entities/general';
import Divider from 'antd/es/divider';
import React, { ReactNode } from 'react';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import RfpSingleTile from '../rfp-single-tile';
import { RfpSidebarSection } from './styles';

type RfpSidebarProps = {
  sections: RfpSidebarField[][];
  isOverview?: boolean;
};

export interface RfpSidebarField {
  label: string;
  value: string | ReactNode;
  Icon?: (props: IconProps) => ReactNode;
}

const RfpSidebar = ({ sections }: RfpSidebarProps) => {
  const theme = useTheme();

  const SidebarField = ({ Icon, label, value }: RfpSidebarField) => {
    return (
      <Div
        flex="column"
        gap={4}
        style={{ paddingLeft: !Icon ? 26 : undefined }}
      >
        <Div flex="row" align="center" gap={15}>
          {!!Icon && <Icon width={12} height={12} />}
          <Typography
            elementTheme="overline"
            medium
            color={theme.colors.grey_2}
            textTransform="uppercase"
          >
            {label}
          </Typography>
        </Div>
        <Typography
          elementTheme="body2"
          style={{ paddingLeft: !!Icon ? 32 : undefined }}
        >
          {value}
        </Typography>
      </Div>
    );
  };
  return (
    <RfpSingleTile>
      <Div flex="column" gap={20}>
        {React.Children.toArray(
          sections.map((fields, i) => (
            <React.Fragment key={`sidebar-section-${i}`}>
              {i > 0 && (
                <Divider
                  style={{ margin: 0, borderColor: theme.colors.grey_4 }}
                />
              )}
              <RfpSidebarSection flex="column" gap={20}>
                {React.Children.toArray(
                  fields.map((field, j) => <SidebarField {...field} />),
                )}
              </RfpSidebarSection>
            </React.Fragment>
          )),
        )}
      </Div>
    </RfpSingleTile>
  );
};

export default RfpSidebar;
