import AppstoreOutlined from '@ant-design/icons/AppstoreOutlined';
import AuditOutlined from '@ant-design/icons/AuditOutlined';
import BarChartOutlined from '@ant-design/icons/BarChartOutlined';
import BlockOutlined from '@ant-design/icons/BlockOutlined';
import CodeOutlined from '@ant-design/icons/CodeOutlined';
import DatabaseOutlined from '@ant-design/icons/DatabaseOutlined';
import DeploymentUnitOutlined from '@ant-design/icons/DeploymentUnitOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import FormOutlined from '@ant-design/icons/FormOutlined';
import FundProjectionScreenOutlined from '@ant-design/icons/FundProjectionScreenOutlined';
import FundViewOutlined from '@ant-design/icons/FundViewOutlined';
import FunnelPlotOutlined from '@ant-design/icons/FunnelPlotOutlined';
import MessageOutlined from '@ant-design/icons/MessageOutlined';
import NotificationOutlined from '@ant-design/icons/NotificationOutlined';
import PartitionOutlined from '@ant-design/icons/PartitionOutlined';
import RobotOutlined from '@ant-design/icons/RobotOutlined';
import RocketOutlined from '@ant-design/icons/RocketOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import WhatsAppOutlined from '@ant-design/icons/WhatsAppOutlined';
import { ReactNode } from 'react';

const getCategoryIcon = (category: string) => {
  const icons: { [index: string]: ReactNode } = {
    undefined: <WhatsAppOutlined />,
    'applications development': <AppstoreOutlined />,
    'business development': <AuditOutlined />,
    training: <FundProjectionScreenOutlined />,
    'marketing strategy': <FundViewOutlined />,
    'translation services': <MessageOutlined />,
    copywriting: <FormOutlined />,
    'managing software': <PartitionOutlined />,
    'printed marketing': <BlockOutlined />,
    'digital marketing': <FunnelPlotOutlined />,
    'audiovisual production': <NotificationOutlined />,
    'augmented reality/vr': <DeploymentUnitOutlined />,
    'recruitment and human resources': <TeamOutlined />,
    'professional services': <RocketOutlined />,
    'it services': <DatabaseOutlined />,
    'artificial intelligence': <RobotOutlined />,
    'website design/modification': <CodeOutlined />,
    design: <EditOutlined />,
    logistics: <BarChartOutlined />,
  };

  if (icons[category] === undefined) {
    return icons['undefined'];
  }

  return icons[category];
};

export default getCategoryIcon;
