import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import ModalLayoutWithIcon from '@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-icon';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import theme from '@hellodarwin/core/lib/theme';
import GreenCheck from '@hellodarwin/icons/dist/icons/GreenCheck';
interface RfpReferralModalProps {
  isVisible: boolean;
  handleCancel: () => void;
}

const RfpReferralModal = ({
  isVisible,
  handleCancel,
}: RfpReferralModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalLayoutWithIcon
      open={isVisible}
      handleCancel={handleCancel}
      iconSize={80}
      Icon={GreenCheck}
      title={t('referral|successModal.title')}
      description={t('referral|successModal.message')}
      Actions={
        <Div flex="column" align="center">
          <Button
            defaultStyle={theme.colors.grey_1}
            onClick={handleCancel}
            size="small"
          >
            {t('referral|successModal.button')}
          </Button>
        </Div>
      }
      withoutBorder
    ></ModalLayoutWithIcon>
  );
};

export default RfpReferralModal;
