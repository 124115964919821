import { prefixHTTPS } from '../../../features/helpers/prefix-https';

/**
 * Normalizes and validates a website URL for Ant Design Form.Item.
 *
 * @param value - The website URL input from the form.
 * @param options - Options to configure the normalization.
 * @param options.ensureProtocol - Whether to add "http://" if missing.
 * @returns The normalized website URL or an empty string if invalid.
 */
const normalizeWebsiteURL = (
  value: string | undefined,
  options: { ensureProtocol?: boolean } = { ensureProtocol: true },
): string => {
  if (!value) return ''; // Return empty string for invalid value

  try {
    let normalizedUrl = value.trim().toLowerCase();

    normalizedUrl = prefixHTTPS(normalizedUrl);

    return normalizedUrl;
  } catch (error) {
    return ''; // Return empty string for invalid URL
  }
};

export default normalizeWebsiteURL;
