import Div from '@hellodarwin/core/lib/components/common/div';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import OnlyCanadianWarningModal from '@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-form/only-canadian-warning';
import validateCheckbox from '@hellodarwin/core/lib/components/forms/utils/validate-checkbox';
import OnboardingFooter from '@hellodarwin/core/lib/components/onboarding/footer';
import OnboardingHeader from '@hellodarwin/core/lib/components/onboarding/header';
import {
  OnBoardingSteps,
  useOnBoarding,
} from '@hellodarwin/core/lib/context/onboarding-provider';
import { ProfileRequest } from '@hellodarwin/core/lib/features/entities';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import Checkbox from 'antd/es/checkbox/Checkbox';
import Form from 'antd/es/form';
import useForm from 'antd/es/form/hooks/useForm';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/app-hooks';
import {
  fetchProfile,
  selectProfile,
  selectProfileLoading,
  updateProfile,
} from '../../../../features/api/slices/profile-slice';
import { usePartnerApi } from '../../../../features/api/use-partner-api';
import { PartnerFormValues } from '../../../../features/entities/form-entities';
import { convertProviderToProfile } from '../../../../features/helpers';
import OnboardingBusinessFormFields from './business-form-fields';
import OnboardingCompanyNameFormFields from './company-name-form-fields';
import OnboardingContactFormFields from './contact-form-fields';

const PartnerOnBoardingProfilePage = () => {
  const { t } = useTranslation();
  const api = usePartnerApi();

  const { currentStep, setCurrentStep } = useOnBoarding();
  const [form] = useForm<PartnerFormValues>();
  const dispatch = useAppDispatch();
  const provider = useAppSelector(selectProfile);
  const isLoading = useAppSelector(selectProfileLoading);

  const handleSave = async (
    values: PartnerFormValues,
    callback: () => void,
  ) => {
    const {
      contact_name,
      contact_phone,
      contact_preferred_language,
      company_email,
      company_name,
      company_description,
      company_size,
      company_website,
      company_type,
      city,
      country,
      terms_and_conditions,
      postal_code,
      project_language,
      profile_language,
      province,
      address,
      lat,
      lng,
    } = values;
    const profileRequest: ProfileRequest = {
      contact_name: !!contact_name
        ? contact_name
        : provider.contacts?.[0]?.name,
      contact_phone: !!contact_phone
        ? contact_phone
        : provider.contacts?.[0]?.phone,
      contact_preferred_language: !!contact_preferred_language
        ? contact_preferred_language
        : provider.contacts?.[0]?.preferred_language,
      contact_email: !!provider.contacts?.[0]?.email
        ? provider.contacts?.[0]?.email
        : company_email,
      company_name: !!company_name ? company_name : provider.name,
      company_email: !!company_email ? company_email : provider.email,
      company_website: !!company_website ? company_website : provider.website,
      company_address: !!address ? address : provider.address,
      company_city: !!city ? city : provider.city,
      company_postal_code: !!postal_code ? postal_code : provider.postal_code,
      company_province: !!province ? province : provider.province,
      company_country: !!country ? country : provider.country,
      project_language: !!project_language
        ? project_language
        : provider.rfp_language,
      profile_language: !!profile_language
        ? profile_language
        : provider.contacts?.[0].preferred_language,
      company_size: !!company_size ? company_size : provider.size,
      company_description: !!company_description
        ? company_description
        : provider.description,
      terms_and_conditions: !!terms_and_conditions
        ? terms_and_conditions
        : provider.terms_and_conditions,
      lat: !!lat ? lat : provider.lat,
      lng: !!lng ? lng : provider.lng,
      company_type: !!company_type ? company_type : provider.type,
    };

    const data = new FormData();
    data.append('request', JSON.stringify(profileRequest));
    await dispatch(updateProfile({ api, data })).then(() =>
      dispatch(fetchProfile({ api })),
    );

    callback();
  };

  useEffect(() => {
    form.setFieldsValue(provider);

    const preferredLanguage = !!provider?.contacts?.[0]?.preferred_language
      ? provider?.contacts?.[0]?.preferred_language
      : undefined;
    const projectLanguage = !!provider.rfp_language
      ? provider.rfp_language
      : undefined;
    const company_name = !!provider.name ? provider.name : '';
    const contact_name = !!provider.contacts?.[0].name
      ? provider.contacts?.[0].name
      : provider.name;

    const phone =
      provider.phone === provider.contacts?.[0].phone
        ? undefined
        : provider.contacts?.[0].phone;

    const email = !!provider.email
      ? provider.email
      : provider.contacts?.[0].email;

    form.setFieldsValue({
      province: provider.province,
      address: provider.address,
      city: provider.city,
      postal_code: provider.postal_code,
      country: provider.country,
      isCanada: provider.country === 'Canada' || provider.country === '',
      company_name,
      contact_preferred_language: preferredLanguage,
      project_language: projectLanguage,
      contact_name,
      contact_phone: phone,
      company_email: email,
      company_website: provider.website,
      company_type: provider.type,
    });
  }, [provider, form]);

  const [canadianWarningModalOpen, setCanadianWarningModalOpen] =
    useState(false);
  const handleCanadianModal = () => {
    setCanadianWarningModalOpen(!canadianWarningModalOpen);
  };

  const handleNext = () => {
    try {
      switch (currentStep) {
        case OnBoardingSteps.CompanyName:
          form
            .validateFields(['company_name'])
            .then(async (values) => {
              handleSave(values, () => setCurrentStep(OnBoardingSteps.Company));
            })
            .catch((e) => console.error(e));
          break;
        case OnBoardingSteps.Company:
          form
            .validateFields([
              'company_website',
              'company_email',
              'project_language',
              'complete',
              'address',
              'city',
              'country',
              'province',
              'postal_code',
              'lng',
              'lat',
            ])
            .then(async (values) => {
              if (values.country !== 'Canada') {
                handleCanadianModal();
              }
              handleSave(values, () => setCurrentStep(OnBoardingSteps.Contact));
            })
            .catch((e) => console.error(e));

          break;
        case OnBoardingSteps.Contact:
          form
            .validateFields()
            .then(async (values) => {
              handleSave(values, () =>
                setCurrentStep(OnBoardingSteps.Category),
              );
            })
            .catch((info) => {
              console.error('Validate Failed:', info);
            });
          break;
      }
    } catch (error) {}
  };
  const handlePrev = () => {
    switch (currentStep) {
      case OnBoardingSteps.Company:
        setCurrentStep(OnBoardingSteps.CompanyName);
        break;
      case OnBoardingSteps.Contact:
        setCurrentStep(OnBoardingSteps.Company);

        break;
    }
  };

  return (
    <PageLayout
      app="provider"
      title={t('onboarding|profile.title')}
      subtitle={t('onboarding|sider.description')}
      breadcrumbs={[]}
    >
      <OnboardingHeader />
      <Form
        form={form}
        initialValues={convertProviderToProfile(provider)}
        layout={'vertical'}
      >
        {currentStep === OnBoardingSteps.CompanyName && (
          <OnboardingCompanyNameFormFields isLoading={isLoading} />
        )}
        {currentStep === OnBoardingSteps.Company && (
          <OnboardingBusinessFormFields isLoading={isLoading} form={form} />
        )}
        {currentStep === OnBoardingSteps.Contact && (
          <Div flex="column" gap={20}>
            <OnboardingContactFormFields isLoading={isLoading} />
            <Form.Item
              name="terms_and_conditions"
              rules={[
                {
                  required: true,
                  validator: validateCheckbox(
                    t(
                      'profile|profileForm.company.validation.termsAndConditions',
                    ),
                  ),
                },
              ]}
              valuePropName="checked"
            >
              <Checkbox>
                {t('profile|profileForm.company.labels.termsAndConditionsText')}{' '}
                <a
                  href={t(
                    'profile|profileForm.company.labels.linkTermsAndConditions',
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('profile|profileForm.company.labels.termsAndConditions')}
                </a>
              </Checkbox>
            </Form.Item>
          </Div>
        )}
      </Form>

      <OnboardingFooter
        handlePrev={currentStep > 0 ? handlePrev : undefined}
        handleNext={handleNext}
        isLoading={isLoading}
      />
      <OnlyCanadianWarningModal
        open={canadianWarningModalOpen}
        handleClose={handleCanadianModal}
      />
    </PageLayout>
  );
};

export default PartnerOnBoardingProfilePage;
