import ConfigProvider from 'antd/es/config-provider';
import Steps from 'antd/es/steps';
import { useMemo } from 'react';
import { useOnBoarding } from '../../context/onboarding-provider';
import theme from '../../theme';
import { Tablet } from '../common/Media';
import Div from '../common/div';

const OnboardingHeader = () => {
  const { currentStep, progressSteps, setCurrentStep } = useOnBoarding();

  const stepItems = useMemo(
    () =>
      progressSteps.map((element) => {
        return {
          title: element.label,
        };
      }),
    [progressSteps],
  );
  return (
    <Tablet direction="up">
      <Div flex="column" style={{ margin: '24px 0' }}>
        <ConfigProvider
          theme={{
            components: {
              Steps: {
                colorText: theme.colors.primary,
                colorPrimary: theme.colors.purple_1,
                colorTextDisabled: theme.colors.purple_2,
                colorSplit: theme.colors.purple_3,
                colorTextDescription: theme.colors.grey_4,
              },
            },
          }}
        >
          <Steps
            onChange={(current) =>
              currentStep > current ? setCurrentStep(current) : undefined
            }
            current={currentStep}
            style={{ color: theme.colors.grey_1 }}
            progressDot
            items={stepItems}
          />
        </ConfigProvider>
      </Div>
    </Tablet>
  );
};

export default OnboardingHeader;
