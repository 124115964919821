import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { RfpRowInformation } from '@hellodarwin/core/lib/features/entities';

type RfpInformationRowProps = {
  info: RfpRowInformation;
  justify?: 'flex-start' | 'flex-end' | 'center';
  tablet?: {
    justify?: 'flex-start' | 'flex-end' | 'center';
  };
};

const RfpInformationRow = ({
  info,
  justify,
  tablet,
}: RfpInformationRowProps) => {
  const { title, value, hide } = info;

  if (!!hide || !value) return <></>;

  return (
    <Div
      flex={'row'}
      align={'center'}
      gap={8}
      wrap={'wrap'}
      justify={justify}
      tablet={tablet}
    >
      <Typography elementTheme="body2">{title}</Typography>
      <Typography elementTheme="body2" bold>
        {value?.toString()?.length < 45
          ? value
          : value.toString().slice(0, 45) + '...'}
      </Typography>
    </Div>
  );
};

export default RfpInformationRow;
