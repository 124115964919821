import {
  EntityState,
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app/app-store';

import {
  ApiError,
  ApiErrorInitialState,
  Contact,
  ProfileModalProps,
} from '@hellodarwin/core/lib/features/entities';
import ProfileBlocks from '@hellodarwin/core/lib/features/enums/profile-blocks';
import PartnerApiClient from '../partner-api-client';
import showErrorNotification from '../../helpers/show-error-notifications';

const contactAdapter = createEntityAdapter({
  selectId: (model: Contact) => model.contact_id,
});

export interface CoreState {
  status: 'idle' | 'pending';
  error: ApiError;
  activeTab: string;
  modal: {
    isVisible: boolean;
    type: string;
  };
  profileModal: ProfileModalProps;
  contacts: EntityState<Contact, string>;
}

const initialState: CoreState = {
  status: 'idle',
  error: ApiErrorInitialState,
  activeTab: 'project',
  modal: {
    isVisible: false,
    type: '',
  },
  profileModal: {
    open: false,
  },
  contacts: contactAdapter.getInitialState(),
};

export const selectActiveTab = (state: RootState) => state.global.activeTab;

export const setActiveTab = createAction<string>('global/setActiveTab');

export const toggleModal = createAction<{ isVisible: boolean; type: string }>(
  'global/toggleModal',
);
export const toggleProfileModal = createAction<{
  open: boolean;
  type?: ProfileBlocks;
  targetId?: string;
}>('global/toggleProfileModal');

export const fetchContactById = createAsyncThunk<
  Contact,
  { api: PartnerApiClient; contactId: string },
  { rejectValue: ApiError }
>(
  'partner/fetchContactById',
  async (
    { api, contactId }: { api: PartnerApiClient; contactId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchContactById(contactId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(toggleModal, (state, { payload }) => {
      state.modal = payload;
    });
    builder.addCase(toggleProfileModal, (state, { payload }) => {
      state.profileModal = payload;
    });
    builder.addCase(setActiveTab, (state, { payload }) => {
      state.activeTab = payload;
    });
    builder.addCase(fetchContactById.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchContactById.fulfilled, (state, { payload }) => {
      contactAdapter.upsertOne(state.contacts, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchContactById.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
  },
});

export const globalReducer = globalSlice.reducer;

export const selectClientState = (state: RootState) => state.global;
export const selectLoading = (state: RootState) =>
  state.global.status === 'pending';
export const { selectById: selectContactById } = contactAdapter.getSelectors(
  (state: RootState) => state.global.contacts,
);
export const selectError = (state: RootState) => state.global.error;
export const selectModal = (state: RootState) => state.global.modal;
export const selectProfileModal = (state: RootState) =>
  state.global.profileModal;
