import { MenuProps } from 'antd/es/menu/menu';
import React, { CSSProperties, ReactNode } from 'react';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import Dropdown from '../../../common/dropdown';
import Typography from '../../../common/typography';
type RfpSingleTileProps = {
  title?: string;
  subtitle?: ReactNode;
  className?: string;
  children: ReactNode;
  dropdownMenuItems?: MenuProps['items'];
  style?: CSSProperties;
};
const RfpSingleTile = ({
  title,
  subtitle,
  className,
  children,
  style,
  dropdownMenuItems,
}: RfpSingleTileProps) => {
  const theme = useTheme();

  return (
    <Div
      backgroundColor={theme.colors.white_1}
      borderColor={theme.colors.primary}
      style={{ padding: 24, height: '100%', ...style }}
      className={className}
      flex="column"
      gap={16}
    >
      {(subtitle || title || dropdownMenuItems) && (
        <Div flex="column" gap={8}>
          {(title || dropdownMenuItems) && (
            <Div flex="row" align="center" justify="space-between">
              {title && (
                <Typography
                  elementTheme="subtitle2"
                  color={theme.colors.primary}
                >
                  {title}
                </Typography>
              )}
              {!!dropdownMenuItems && (
                <Dropdown
                  items={dropdownMenuItems}
                  placement="bottomRight"
                  dropdownRender={(menu) =>
                    React.cloneElement(menu as React.ReactElement, {
                      style: { border: `1px solid ${theme.colors.primary}` },
                    })
                  }
                />
              )}
            </Div>
          )}
          {!!subtitle && (
            <Typography elementTheme="caption" color={theme.colors.grey_2}>
              {subtitle}
            </Typography>
          )}
        </Div>
      )}
      {children}
    </Div>
  );
};
export default RfpSingleTile;
