import { TabsProps } from 'antd/es/tabs';
import React, {
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TabBarItem, TabsBarContainer, TabsContainer } from './styles';

const Tabs = ({ items, defaultActiveKey, onTabClick, ...props }: TabsProps) => {
  const [activeKey, setActiveKey] = useState(defaultActiveKey ?? '');

  const ref = useRef<HTMLDivElement>(null);
  const [underlineWidth, setUnderlineWidth] = useState<number>(0);
  const [underlineOffsetX, setUnderlineOffsetX] = useState<number>(0);

  useEffect(() => {
    setActiveKey(defaultActiveKey ?? '');
  }, [defaultActiveKey]);

  useEffect(() => {
    if (!ref.current) return;
    const activeTab: HTMLDivElement = ref.current.querySelector(
      `.${activeKey}`,
    ) as HTMLDivElement;

    if (!!activeTab) {
      setUnderlineWidth(activeTab.clientWidth);
      setUnderlineOffsetX(activeTab.offsetLeft);
    }
  }, [activeKey]);

  const handleTabClick = (
    activeKey: string,
    e: KeyboardEvent<Element> | MouseEvent<Element>,
  ) => {
    if (!!onTabClick) {
      onTabClick(activeKey, e);
    } else {
      setActiveKey(activeKey);
    }
  };

  return (
    <TabsContainer
      items={items}
      activeKey={activeKey}
      onTabClick={handleTabClick}
      renderTabBar={({ onTabClick }) => (
        <TabsBarContainer
          $underlineOffsetX={underlineOffsetX}
          $underlineWidth={underlineWidth}
          ref={ref}
        >
          {React.Children.toArray(
            items?.map((itm) => (
              <TabBarItem
                onClick={(e) => onTabClick(itm.key, e)}
                elementTheme="body2"
                className={itm.key}
                nowrap
                style={{ letterSpacing: 0.16, paddingBottom: 14 }}
              >
                {itm.label}
              </TabBarItem>
            )),
          )}
        </TabsBarContainer>
      )}
      {...props}
    />
  );
};

export default Tabs;
