import { useState } from 'react';

import { ProviderStatus } from '@hellodarwin/core/lib/features/entities';

import { useAppSelector } from '../../../app/app-hooks';
import UnverifiedProfileModal from '../../messages/unverified-profile-modal';

import Modal from '@hellodarwin/core/lib/components/common/hd-modal';
import { useNavigate } from 'react-router-dom';
import { selectProfile } from '../../../features/api/slices/profile-slice';
import ErrorModal from './error-modal';
import PaymentConfirmation from './payment-confirmation';
import PaymentForm from './payment-form';
import RaiseHandSuccess from './rh-success';

type RaiseHandPaymentModalProps = {
  open: boolean;
  onCancel: () => void;
};

type PaymentStep = 'form' | 'confirmation' | 'success' | 'error';

const RaiseHandPaymentModal = ({
  open,
  onCancel,
}: RaiseHandPaymentModalProps) => {
  const navigate = useNavigate();

  const [paymentStep, setPaymentStep] = useState<PaymentStep>('form');
  const [error, setError] = useState<number>();
  const [helloMessage, setHelloMessage] = useState<string>();

  const profile = useAppSelector(selectProfile);

  const [waiveFee, setWaiveFee] = useState(
    (profile.available_raise_hands || 0) > 0,
  );

  const handleSuccessCancel = () => {
    onCancel();
    navigate('/projects/ongoing');
  };

  if (profile.status !== ProviderStatus.Verified) {
    return <UnverifiedProfileModal isVisible={open} onCancel={onCancel} />;
  }

  const handleSuccess = () => setPaymentStep('success');
  const handleFormConfirm = () => setPaymentStep('confirmation');
  const handleRaiseHandError = (err: any) => {
    setPaymentStep('error');

    if (err.error) {
      setError(err.error_code);
    } else if (err?.response?.data?.error_code) {
      setError(err.response.data.error_code);
    }
  };

  return (
    <Modal
      open={open}
      handleCancel={onCancel}
      size={['form'].includes(paymentStep) ? 'large' : 'small'}
    >
      {paymentStep === 'form' ? (
        <PaymentForm
          onCancel={onCancel}
          handleFormConfirm={handleFormConfirm}
          availableRaisehands={profile.available_raise_hands || 0}
          setWaiveFee={setWaiveFee}
          waiveFee={waiveFee}
          helloMessage={helloMessage}
          setHelloMessage={setHelloMessage}
        />
      ) : paymentStep === 'confirmation' ? (
        <PaymentConfirmation
          onCancel={onCancel}
          availableRaisehands={profile.available_raise_hands || 0}
          feeWaived={waiveFee}
          handleSuccess={handleSuccess}
          handleError={handleRaiseHandError}
          helloMessage={helloMessage}
        />
      ) : paymentStep === 'success' ? (
        <RaiseHandSuccess handleSuccessCancel={handleSuccessCancel} />
      ) : paymentStep === 'error' && error ? (
        <ErrorModal open={open} onCancel={onCancel} error={error} />
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default RaiseHandPaymentModal;
