import Div from '@hellodarwin/core/lib/components/common/div';
import ModalWarning from '@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-warning';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import EmailEnum from '@hellodarwin/core/lib/features/enums/email';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import theme from '@hellodarwin/core/lib/theme';
type UnverifiedModalProps = {
  isVisible: boolean;
  onCancel: () => void;
};

const UnverifiedProfileModal = ({
  isVisible,
  onCancel,
}: UnverifiedModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalWarning open={isVisible} handleCancel={onCancel}>
      <Div flex="column" gap={16} style={{ maxWidth: '80%' }}>
        <Typography.Title level={2} elementTheme="h5" textAlign="center">
          {t('common|title.unverifiedProfile')}
        </Typography.Title>
        <Typography
          center={true}
          color={theme.colors.grey_2}
          textAlign="center"
        >
          {t('common|message.unverifiedProfile')}
          <a href={`mailto:${EmailEnum.Support}`}>{EmailEnum.Support}</a>
        </Typography>
      </Div>
    </ModalWarning>
  );
};

export default UnverifiedProfileModal;
