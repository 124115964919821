import Div from '@hellodarwin/core/lib/components/common/div';
import Breadcrumbs from '@hellodarwin/core/lib/components/layout/header/breadcrumbs';
import UserMenu from '@hellodarwin/core/lib/components/layout/header/user-menu';
import { NavigationLink } from '@hellodarwin/core/lib/features/entities';
import useMediaQuery from '@hellodarwin/core/lib/features/helpers/use-media-query';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import Employees from '@hellodarwin/icons/dist/icons/Employees';
import Settings from '@hellodarwin/icons/dist/icons/Settings';
import Layout from 'antd/es/layout';
import { useAppSelector } from '../app/index';
import { selectProfile } from '../features/api/slices/profile-slice';

const { Header } = Layout;

const PageHeader = ({ isOnboarding }: { isOnboarding?: boolean }) => {
  const { t } = useTranslation();
  //Selector
  const provider = useAppSelector(selectProfile);

  //Links
  const menuLinks: NavigationLink[] = [
    {
      label: t('navigation.settings'),
      pathname: '/settings',
      Icon: Settings,
    },
    {
      label: t('navigation.users'),
      pathname: '/settings/users',
      Icon: Employees,
    },
  ];

  //Functions

  const isTablet = useMediaQuery({ maxWidth: 992 });

  if (isTablet) return <></>;

  return (
    <Header style={{ padding: 0, height: 40 }}>
      <Div
        flex="row"
        align="center"
        justify="space-between"
        style={{ height: '100%' }}
      >
        <Breadcrumbs />
        <UserMenu
          user={{
            name: provider.contacts?.[0]?.name || provider.name,
            logo: provider.logo,
          }}
          links={!isOnboarding ? menuLinks : []}
          app="provider"
        />
      </Div>
    </Header>
  );
};

export default PageHeader;
