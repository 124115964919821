import Div from '@hellodarwin/core/lib/components/common/div';
import HdTag from '@hellodarwin/core/lib/components/common/hd-tag';

type RfpSpecialtiesProps = {
  specialties: string[];
};

const RfpSpecialties = ({ specialties }: RfpSpecialtiesProps) => {
  if (!specialties) {
    return <></>;
  }

  return (
    <Div flex={'row'} fitContent gap={8} wrap={'wrap'}>
      {specialties.slice(0, 4).map((tag) => (
        <HdTag text={`${tag}`} translateTag />
      ))}
      {specialties.length > 4 && <HdTag text={'...'} />}
    </Div>
  );
};

export default RfpSpecialties;
