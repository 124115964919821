import HdLogo from '@hellodarwin/icons/dist/icons/HdLogo';
import { AppType } from '../../../features/entities';
import useLocale from '../../../features/providers/locale-provider';
import theme from '../../../theme';
import Button from '../../common/button';
import Div from '../../common/div';

type MobileOnboardingSiderProps = {
  appType: AppType;
};

const MobileOnboardingSider = ({ appType }: MobileOnboardingSiderProps) => {
  const { selectedLocale, changeLanguage } = useLocale();

  const handleLangChange = () => {
    const otherLang = selectedLocale === 'fr' ? 'en' : 'fr';
    changeLanguage(otherLang);
  };
  return (
    <Div flex={'column'} gap={16}>
      <Div
        flex={'row'}
        justify={'center'}
        align="center"
        backgroundColor={
          appType === 'client' ? theme.colors.white_1 : theme.colors.grey_1
        }
        style={{ height: 100, width: '100%' }}
      >
        <HdLogo backgroundType="dark" type="primary" color="b/w" />
      </Div>

      <Div flex={'row'} position="absolute" justify="flex-end">
        <Button fitContent transparent onClick={handleLangChange}>
          {selectedLocale === 'fr' ? 'EN' : 'FR'}
        </Button>
      </Div>
    </Div>
  );
};

export default MobileOnboardingSider;
