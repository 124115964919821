import { TabsProps } from 'antd/es/tabs';
import { useTheme } from '../../../../../plugins/styled';
import { RfpMenuTabs } from './styles';

export type RfpMenuProps = {
  tabs: TabsProps['items'];
  activeTab: string;
  handleTabClick: (tabKey: string) => void;
};

const RfpMenu = ({ tabs, activeTab, handleTabClick }: RfpMenuProps) => {
  const theme = useTheme();
  return (
    <RfpMenuTabs
      activeKey={activeTab}
      onTabClick={handleTabClick}
      items={tabs}
      style={{
        fontWeight: theme.fontWeights.regular,
        fontSize: theme.fonts.buttonC,
        lineHeight: 'normal',
      }}
      className="rfp-menu"
    />
  );
};

export default RfpMenu;
