import { RfpMenuProps } from '../rfp-menu';
import { RfpMenuMobileTabs } from './styles';

const RfpMenuMobile = ({ tabs, activeTab, handleTabClick }: RfpMenuProps) => {
  if (!tabs?.length) return <></>;

  return (
    <RfpMenuMobileTabs
      defaultActiveKey="1"
      items={tabs}
      type="card"
      activeKey={activeTab}
      onTabClick={handleTabClick}
    />
  );
};

export default RfpMenuMobile;
