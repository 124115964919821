import Form from 'antd/es/form';
import Input from 'antd/es/input/Input';
import Select from 'antd/es/select';
import { ReactNode, useEffect } from 'react';
import { Contact } from '../../../../features/entities/core-entities';
import SingleLanguage from '../../../../features/enums/single-language';
import { useTranslation } from '../../../../plugins/i18n';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import FormItem from '../../form-layouts/form-item';
import FormLabel from '../../form-layouts/form-label';
import validateEmail from '../../utils/validate-email';
import validatePhoneNumber from '../../utils/validate-phone-number';
import ProfileFormFooter from './profile-form-footer';

type ProfileContactFormProps = {
  contact: Contact;
  handleSave: (values: Contact) => void;
};

const ProfileContactForm = ({
  contact,
  handleSave,
}: ProfileContactFormProps) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<Contact>();

  const languageOptions: {
    value: string;
    label: string | ReactNode;
  }[] = [
    {
      value: SingleLanguage.French,
      label: t(`language.${SingleLanguage.French}`),
    },
    {
      value: SingleLanguage.English,
      label: t(`language.${SingleLanguage.English}`),
    },
  ];

  const handleReset = () => {
    form.setFieldsValue(contact);
  };

  useEffect(() => {
    handleReset();
  }, [contact]);

  const handleFinish = () => {
    form.submit();
  };
  return (
    <Div flex="column" gap={32}>
      <Typography.Title level={2} elementTheme="h6">
        {t('profile|profileForm.contact.title')}
      </Typography.Title>

      <Div flex="column" gap={28}>
        <Form
          form={form}
          layout={'vertical'}
          name={'profile-contact-form'}
          initialValues={contact}
          onFinish={handleSave}
        >
          <Div flex="column" gap={32}>
            <Div
              flex="row"
              style={{ gap: 56 }}
              tablet={{ flex: 'column', gap: 32 }}
            >
              <FormItem
                style={{ flex: 3 }}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('profile|profileForm.contact.validation.name'),
                  },
                ]}
                label={
                  <FormLabel
                    label={t('profile|profileForm.contact.labels.name')}
                  />
                }
              >
                <Input
                  size={'large'}
                  placeholder={t(
                    'profile|profileForm.contact.placeholder.name',
                  )}
                />
              </FormItem>
              <FormItem
                style={{ flex: 2 }}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: t('profile|profileForm.contact.validation.phone'),
                    validator: validatePhoneNumber(
                      t('profile|profileForm.contact.validation.phone'),
                    ),
                  },
                ]}
                label={
                  <FormLabel
                    label={t('profile|profileForm.contact.labels.phone')}
                  />
                }
              >
                <Input
                  size={'large'}
                  placeholder={t(
                    'profile|profileForm.contact.placeholder.phone',
                  )}
                />
              </FormItem>
            </Div>
            <Div
              flex="row"
              style={{ gap: 56 }}
              tablet={{ flex: 'column', gap: 32 }}
            >
              <FormItem
                style={{ flex: 3 }}
                name="email"
                rules={[
                  {
                    required: true,
                    message: t('profile|profileForm.contact.validation.email'),
                    validator: validateEmail(
                      t('profile|profileForm.contact.validation.email'),
                    ),
                  },
                ]}
                label={
                  <FormLabel
                    label={t('profile|profileForm.contact.labels.email')}
                    extra={t('profile|profileForm.contact.extra.email')}
                  />
                }
              >
                <Input
                  size={'large'}
                  type="email"
                  placeholder={t(
                    'profile|profileForm.contact.placeholder.email',
                  )}
                />
              </FormItem>

              <FormItem
                style={{ flex: 2 }}
                name="preferred_language"
                rules={[
                  {
                    required: true,
                    message: t(
                      'profile|profileForm.contact.validation.preferredLanguage',
                    ),
                  },
                ]}
                label={
                  <FormLabel
                    label={t(
                      'profile|profileForm.contact.labels.preferredLanguage',
                    )}
                    extra={t(
                      'profile|profileForm.contact.extra.preferredLanguage',
                    )}
                  />
                }
              >
                <Select
                  options={languageOptions}
                  size="large"
                  placeholder={t(
                    'profile|profileForm.contact.labels.preferredLanguage',
                  )}
                />
              </FormItem>
            </Div>
          </Div>
        </Form>
      </Div>
      <ProfileFormFooter
        handleFinish={handleFinish}
        handleReset={handleReset}
      />
    </Div>
  );
};
export default ProfileContactForm;
