import MessageCard from '@hellodarwin/core/lib/components/rfp/single/rfp-messages/message-card';
import { Contact } from '@hellodarwin/core/lib/features/entities/core-entities';
import { Match } from '@hellodarwin/core/lib/features/entities/matches-entities';
import { getStringDate } from '@hellodarwin/core/lib/features/helpers/get-formatted-date';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';

type RfpShortlistMessageProps = {
  match: Match;
  rfpContact?: Contact;
};

const RfpShortlistMessage = ({
  match,
  rfpContact,
}: RfpShortlistMessageProps) => {
  const { selectedLocale } = useLocale();

  if (!rfpContact) return <></>;

  const contactMessage = match.shortlist_message;
  const clientMessage = match.hello_message;
  const contactName = match.purchased_at ? rfpContact?.name : '';
  const shortlistedAt = getStringDate(
    new Date(match.purchased_at || ''),
    selectedLocale,
  );

  if (!contactMessage) return <></>;

  return (
    <MessageCard
      messagedFirst="client"
      sent_at={shortlistedAt}
      clientMessage={clientMessage}
      contactMessage={contactMessage}
      contactName={contactName ?? ''}
    />
  );
};

export default RfpShortlistMessage;
