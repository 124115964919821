import { API_URL } from '@hellodarwin/core/lib/api';
import { OauthOptions } from '@hellodarwin/core/lib/features/providers/auth0-provider-with-navigate';
import axios from 'axios';

// hasAuthParams is copied from @auth0/auth0-react/src/utils.tsx because CRA can't seem to resolve it
export const hasAuthParams = (
  searchParams = window?.location?.search || '',
): boolean => {
  const CODE_RE = /[?&]code=[^&]+/;
  const STATE_RE = /[?&]state=[^&]+/;
  const ERROR_RE = /[?&]error=[^&]+/;
  return (
    (CODE_RE.test(searchParams) && STATE_RE.test(searchParams)) ||
    ERROR_RE.test(searchParams)
  );
};

// fetchOauthOptions from the API
export const fetchOauthOptions = async (
  appName: string,
): Promise<OauthOptions> => {
  const response = await axios
    .create({ baseURL: API_URL })
    .get<OauthOptions>(`/oauth/${appName}`);

  return { ...response.data };
};
