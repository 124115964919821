import Divider from 'antd/es/divider';
import { ReactNode, useMemo } from 'react';
import { ReviewResult } from '../../../../features/entities/reviews-entities';
import getAverageAllReviews from '../../../../features/helpers/get-average-reviews';
import { getStringDateFromString } from '../../../../features/helpers/get-formatted-date';
import useLocale from '../../../../features/providers/locale-provider';
import { useTheme } from '../../../../plugins/styled';
import Avatar from '../../../common/avatar';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import ReviewRatings from '../../../reviews/ratings';
import { MessageCardContainer } from './styles';

interface ContactInfo {
  contactName: string;
  city?: string;
  province?: string;
  sent_at: string;
  reviews?: ReviewResult[];
  logo?: string;
}
interface MessageCardProps extends ContactInfo {
  actions?: ReactNode;
  contactMessage?: string;
  clientMessage?: string;
  messagedFirst?: 'client' | 'contact';
}

const MessageCard = ({
  actions,
  contactMessage,
  clientMessage,
  messagedFirst,
  ...contact
}: MessageCardProps) => {
  const theme = useTheme();

  const ContactInformations = ({
    reviews,
    contactName,
    sent_at,
    city,
    province,
  }: ContactInfo) => {
    const { selectedLocale } = useLocale();
    const average = useMemo(() => {
      return reviews ? getAverageAllReviews(reviews) : undefined;
    }, [reviews]);
    return (
      <Div
        flex="row"
        gap={24}
        align="center"
        justify="space-between"
        tablet={{
          flex: 'column',
          align: 'center',
        }}
      >
        <Div flex="column" gap={8}>
          <Typography
            elementTheme="subtitle2"
            nowrap
            ellipsis
            tablet={{ textAlign: 'center' }}
          >
            {contactName}
          </Typography>
          {(!!city || average || reviews) && (
            <Div
              flex="row"
              align="center"
              gap={10}
              tablet={{ flex: 'column', align: 'center' }}
            >
              {!!city && (
                <Typography
                  elementTheme="overline"
                  color={theme.colors.grey_2}
                  fitContent
                  tablet={{ textAlign: 'center' }}
                >
                  {`${city}, ${province}`}
                </Typography>
              )}
              {!!city && average && reviews && (
                <Divider
                  type="vertical"
                  style={{
                    margin: 0,
                    height: '15px',
                    top: 0,
                    borderColor: theme.colors.grey_4,
                  }}
                />
              )}
              {average && reviews && (
                <ReviewRatings
                  size="extra-small"
                  showOne
                  defaultValue={Math.round(average * 10) / 10}
                  showValue
                  textPlacement="end"
                  allowHalf
                />
              )}
            </Div>
          )}
        </Div>
        {!!sent_at && (
          <Typography elementTheme="body3" color={theme.colors.grey_2} nowrap>
            {getStringDateFromString(sent_at || '', selectedLocale)}
          </Typography>
        )}
      </Div>
    );
  };

  const MessageInfo = ({
    message,
    isClient,
  }: {
    message: string;
    isClient?: boolean;
  }) => {
    return (
      <div
        style={{
          paddingLeft: isClient ? 24 : 0,
          paddingRight: !isClient ? 24 : 0,
        }}
      >
        <Div
          backgroundColor={
            isClient ? theme.colors.green_4 : theme.colors.beige_3
          }
          borderColor={isClient ? theme.colors.green_3 : theme.colors.beige_2}
          style={{
            padding: '8px 16px',
          }}
        >
          <Typography elementTheme="body2">{message}</Typography>
        </Div>
      </div>
    );
  };

  return (
    <MessageCardContainer>
      <Avatar src={contact.logo} size={46} shape="square">
        {contact.contactName[0]}
      </Avatar>
      <Div flex="column" gap={16} tablet={{ align: 'center' }}>
        <ContactInformations {...contact} />

        <Div
          flex={messagedFirst === 'client' ? 'column-reverse' : 'column'}
          gap={16}
          tablet={{ align: 'center' }}
        >
          {contactMessage && <MessageInfo message={contactMessage} />}
          {clientMessage && <MessageInfo message={clientMessage} isClient />}
        </Div>

        {!!actions && <div style={{ width: 'fit-content' }}>{actions}</div>}
      </Div>
    </MessageCardContainer>
  );
};

export default MessageCard;
