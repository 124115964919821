import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import TextArea from 'antd/es/input/TextArea';
import { Dispatch, SetStateAction } from 'react';

interface PaymentFormMessageProps {
  setHelloMessage: Dispatch<SetStateAction<string | undefined>>;
  helloMessage: string | undefined;
}

const PaymentFormMessage = ({
  setHelloMessage,
  helloMessage,
}: PaymentFormMessageProps) => {
  const { t } = useTranslation();

  const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setHelloMessage(e.currentTarget.value);
  };

  return (
    <TextArea
      styles={{ count: { bottom: -24 } }}
      placeholder={t('modal|raiseHand.paymentForm.placeHolder')}
      rows={7}
      value={helloMessage}
      onChange={handleChange}
      style={{ width: '100%' }}
      showCount
      count={{ show: true, max: 500 }}
    />
  );
};

export default PaymentFormMessage;
