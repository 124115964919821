import { useTranslation } from '../../plugins/i18n';
import theme from '../../theme';
import Button from '../common/button';
import Div from '../common/div';

interface FooterProps {
  handleNext: () => void;
  handlePrev?: () => void;
  nextDisabled?: boolean;
  isLoading?: boolean;
  lastStep?: boolean;
}

const OnboardingFooter = ({
  handleNext,
  handlePrev,
  nextDisabled,
  isLoading,
  lastStep,
}: FooterProps) => {
  const { t } = useTranslation();

  return (
    <Div flex="column" gap={24} style={{ marginTop: 24 }}>
      <Div
        flex={'row'}
        justify={'center'}
        align={'center'}
        alignSelf={'flex-end'}
        gap={10}
      >
        {handlePrev !== undefined && (
          <Button
            defaultStyle={theme.colors.transparent}
            withArrowLeft
            size="small"
            onClick={handlePrev}
          >
            {t('button.back')}
          </Button>
        )}
        <Button
          defaultStyle={theme.colors.purple_1}
          withArrowRight
          loading={isLoading}
          onClick={handleNext}
          size="small"
          disabled={nextDisabled}
        >
          {lastStep ? t('button.submit') : t('button.next')}
        </Button>
      </Div>
    </Div>
  );
};

export default OnboardingFooter;
