import Div from '@hellodarwin/core/lib/components/common/div';
import { Dispatch, SetStateAction } from 'react';
import { useAppSelector } from '../../../../app/app-hooks';
import { selectSelectedMatch } from '../../../../features/api/slices/matches-slice';
import PaymentFormFooter from './payment-form-footer';
import PaymnentFormHeader from './payment-form-header';
import PaymentFormMessage from './payment-form-message';

type PaymentFormProps = {
  onCancel: () => void;
  handleFormConfirm: () => void;
  availableRaisehands: number;
  setWaiveFee: Dispatch<SetStateAction<boolean>>;
  waiveFee: boolean;
  helloMessage?: string;
  setHelloMessage: Dispatch<SetStateAction<string | undefined>>;
};

const PaymentForm = ({
  onCancel,
  handleFormConfirm,
  availableRaisehands,
  setWaiveFee,
  waiveFee,
  helloMessage,
  setHelloMessage,
}: PaymentFormProps) => {
  const match = useAppSelector(selectSelectedMatch);
  if (!match.rfp) return <></>;

  return (
    <Div flex={'column'} gap={16}>
      <PaymnentFormHeader match={match} waiveFee={waiveFee} />
      <PaymentFormMessage
        helloMessage={helloMessage}
        setHelloMessage={setHelloMessage}
      />
      <PaymentFormFooter
        match={match}
        waiveFee={waiveFee}
        setWaiveFee={setWaiveFee}
        availableRaisehands={availableRaisehands}
        handleFormConfirm={handleFormConfirm}
        onCancel={onCancel}
      />
    </Div>
  );
};

export default PaymentForm;
