import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { RaiseHandParams } from '@hellodarwin/core/lib/features/entities';
import { getShortId } from '@hellodarwin/core/lib/features/helpers';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { unwrapResult } from '@reduxjs/toolkit';

import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import { useAppDispatch, useAppSelector } from '../../../app/app-hooks';
import {
  raiseHand,
  selectMatchesLoading,
  selectSelectedMatch,
} from '../../../features/api/slices/matches-slice';
import { usePartnerApi } from '../../../features/api/use-partner-api';

type PaymentConfirmationProps = {
  onCancel: () => void;
  feeWaived: boolean;
  availableRaisehands: number;
  handleError: (err: any) => void;
  handleSuccess: () => void;
  helloMessage?: string;
};

const PaymentConfirmation = ({
  onCancel,
  feeWaived,
  availableRaisehands,
  handleError,
  handleSuccess,
  helloMessage,
}: PaymentConfirmationProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const loading = useAppSelector(selectMatchesLoading);
  const dispatch = useAppDispatch();
  const api = usePartnerApi();

  const match = useAppSelector(selectSelectedMatch);

  const handleRaiseHand = async () => {
    dispatch(
      raiseHand({
        api,
        params: {
          matchId: match.match_id,
          helloMessage: helloMessage,
          raiseHandPrice: match.raise_hand_price || 0,
          waiveRhPrice: feeWaived,
        } as RaiseHandParams,
      }),
    )
      .then(unwrapResult)
      .then(handleSuccess)
      .catch(handleError);
  };

  return (
    <Div flex={'column'} gap={32}>
      <Div flex="column" gap={16}>
        <Typography.Title level={2} elementTheme="h4">
          {t('modal|raiseHand.paymentConfirmation.title')}
        </Typography.Title>
        <Typography elementTheme="body2">
          {t('modal|raiseHand.paymentConfirmation.text', {
            leadPrice: (match.lead_price || 0) - (match.discounted_amount || 0),
            raiseHandPrice:
              feeWaived || match.source === 'Directory'
                ? 0
                : match.rfp?.raise_hand_price || 0,
            id: getShortId(match.rfp_id),
          })}
        </Typography>
        {feeWaived && (
          <Typography>
            {t('modal|raiseHand.paymentConfirmation.freeRaiseHandLeft')}
            {availableRaisehands <= 1
              ? t('modal|raiseHand.paymentConfirmation.remaining', {
                  count: availableRaisehands,
                })
              : t('modal|raiseHand.paymentConfirmation.remainingPlural', {
                  count: availableRaisehands,
                })}
          </Typography>
        )}
        <Typography elementTheme="body3" color={theme.colors.grey_2}>
          {t('modal|raiseHand.paymentConfirmation.note')}
        </Typography>
      </Div>
      <Div
        flex={'row'}
        gap={16}
        justify={'flex-end'}
        tablet={{ flex: 'column-reverse', align: 'center' }}
      >
        <Button
          defaultStyle={theme.colors.white_1}
          onClick={onCancel}
          size="small"
        >
          {t('common|button.cancel')}
        </Button>
        <Button
          defaultStyle={theme.colors.yellow_1}
          onClick={handleRaiseHand}
          loading={loading}
          disabled={loading}
          size="small"
        >
          {t('modal|raiseHand.paymentConfirmation.confirm')}
        </Button>
      </Div>
    </Div>
  );
};

export default PaymentConfirmation;
