import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { Match } from '@hellodarwin/core/lib/features/entities';
import parse, {
  domToReact,
  HTMLReactParserOptions,
} from '@hellodarwin/core/lib/features/helpers/parse';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';

interface PaymnentFormHeaderProps {
  match: Match;
  waiveFee: boolean;
}

const paymentFormParseOptions: HTMLReactParserOptions = {
  replace: (domNode: any) => {
    if (domNode.attribs && domNode.name === 'p') {
      return (
        <Typography elementTheme="body2">
          {domToReact(domNode.children)}
        </Typography>
      );
    }
    if (domNode.attribs && domNode.name === 'ul') {
      return (
        <ul style={{ marginBlock: 8 }}>
          {domToReact(domNode.children, paymentFormParseOptions)}
        </ul>
      );
    }

    if (domNode.attribs && domNode.name === 'li') {
      return (
        <li style={{ marginBlock: 8 }}>
          <Typography elementTheme="body2">
            {domToReact(domNode.children)}
          </Typography>
        </li>
      );
    }
  },
};

const PaymnentFormHeader = ({ match, waiveFee }: PaymnentFormHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Div flex="column" gap={16}>
      <Typography.Title level={2} elementTheme="h4">
        {t('modal|raiseHand.paymentForm.title')}
      </Typography.Title>

      <div>
        {parse(
          t('modal|raiseHand.paymentForm.text', {
            leadPrice: (match.lead_price || 0) - (match.discounted_amount || 0),
            raiseHandPrice:
              waiveFee || match.source === 'Directory'
                ? 0
                : match.rfp.raise_hand_price || 0,
          }),
          paymentFormParseOptions,
        )}
      </div>
    </Div>
  );
};

export default PaymnentFormHeader;
