import NavigationSider from '@hellodarwin/core/lib/components/layout/navigation';
import { NavigationLink } from '@hellodarwin/core/lib/features/entities';
import SingleLanguage from '@hellodarwin/core/lib/features/enums/single-language';
import getAgenciesPage from '@hellodarwin/core/lib/features/helpers/get-agencies-page';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import Accueil from '@hellodarwin/icons/dist/icons/Accueil';
import Employees from '@hellodarwin/icons/dist/icons/Employees';
import Grants from '@hellodarwin/icons/dist/icons/Grants';
import Help from '@hellodarwin/icons/dist/icons/Help';
import ProjectsList from '@hellodarwin/icons/dist/icons/ProjectsList';
import Refereral from '@hellodarwin/icons/dist/icons/Refereral';
import Settings from '@hellodarwin/icons/dist/icons/Settings';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../app/app-hooks';
import {
  selectFormatedProfile,
  selectProfile,
} from '../features/api/slices/profile-slice';
import { usePartnerApi } from '../features/api/use-partner-api';
import showErrorNotification from '../features/helpers/show-error-notifications';

const Navigation = () => {
  const { t } = useTranslation();
  const api = usePartnerApi();

  const provider = useAppSelector(selectProfile);
  const profile = useAppSelector(selectFormatedProfile);

  const [publicProfileUrl, setPublicProfileUrl] = useState<string>('');
  useEffect(() => {
    (async () => {
      getAgenciesPage({
        provider: provider,
        locale: provider.profile_language as SingleLanguage,
      }).then((page) => {
        setPublicProfileUrl(page);
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  //Functions
  const openBillingSession = () => {
    (async () => {
      try {
        const session = await api.fetchBillingSession();
        window.location.href = session.url;
      } catch (err: any) {
        showErrorNotification(err.response.data.error_code);
      }
    })();
  };

  const OpenFundingExplorer = () => {
    (async () => {
      try {
        window.open(
          'https://app.hellodarwin.com/funding-explorer?utm_source=helloDarwin&utm_medium=partner-app&utm_campaign=partner-nav',
          '_blank',
        );
      } catch (err: any) {
        showErrorNotification(err.response.data.error_code);
      }
    })();
  };

  const links: NavigationLink[] = useMemo(() => {
    const links: NavigationLink[] = [
      {
        label: t('navigation.dashboard'),
        pathname: '/dashboard',

        Icon: Accueil,
      },
      {
        pathname: '/projects',
        label: t('navigation.opportunities'),
        Icon: ProjectsList,
        subLinks: [
          {
            pathname: '/projects/new',
            label: t('navigation.newOpportunities'),
          },
          {
            pathname: '/projects/ongoing',
            label: t('navigation.onGoingOpportunities'),
          },
          {
            pathname: '/projects/closed',
            label: t('navigation.completedOpportunities'),
          },
        ],
      },
      {
        label: t('navigation.profile'),
        pathname: '/profile',
        Icon: Employees,
        subLinks: [
          {
            label: t('navigation.edit'),
            pathname: '/profile/edit',
          },
          {
            label: t('navigation.publicProfile'),
            isExternal: true,
            pathname: '/profile/external',
            external_link: publicProfileUrl,
            hide: !publicProfileUrl,
          },
          {
            label: t('navigation.expertise'),
            pathname: '/expertise',
          },
          {
            label: t('navigation.reviews'),
            pathname: '/reviews',
            isNew: true,
          },
        ],
      },
      {
        pathname: '/referral',
        label: t('navigation.referral'),
        Icon: Refereral,
        subLinks: [
          {
            pathname: '/referral/myreferrals',
            label: t('navigation.myReferral'),
          },
          {
            pathname: '/referral/refer',
            label: t('navigation.referralRefer'),
          },
        ],
      },
      {
        Icon: Grants,
        pathname: '/billing',
        label: t('navigation.billing'),
        isExternal: true,
        onClick: openBillingSession,
      },
      {
        pathname: 'funding-explorer',
        label: t('navigation.fundingExplorer'),
        Icon: Grants,
        isExternal: true,
        onClick: OpenFundingExplorer,
      },
      {
        pathname: '/faq',
        label: t('navigation.faq'),
        Icon: Help,
      },
    ];

    return links;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicProfileUrl]);

  //User Links
  const userLinks: NavigationLink[] = useMemo(() => {
    const links: NavigationLink[] = [
      {
        label: t('navigation.settings'),
        pathname: '/settings',
        Icon: Settings,
      },
    ];

    return links;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NavigationSider
      links={links}
      app="provider"
      userLinks={userLinks}
      profile={profile}
    />
  );
};

export default Navigation;
