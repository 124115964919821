import {
  ApiError,
  ApiErrorInitialState,
  MappedMatches,
  Match,
  MatchInitialState,
  PurchasedRfp,
  RaiseHandParams,
  Rfp,
} from '@hellodarwin/core/lib/features/entities';
import {
  EntityState,
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { Token } from '@stripe/stripe-js';
import { RootState } from '../../../app/app-store';
import showErrorNotification from '../../helpers/show-error-notifications';
import { createMatchAdapter } from '../adapters/match-adapter';
import PartnerApiClient from '../partner-api-client';

const matchAdapter = createMatchAdapter();
const purchasedRfpAdapter = createEntityAdapter({
  selectId: (model: PurchasedRfp) => model.match_id,
});
const referRfpAdapter = createEntityAdapter({
  selectId: (model: Rfp) => model.rfp_id || '',
});

export interface MatchesState {
  status: 'idle' | 'pending';
  error: ApiError;
  matches: MappedMatches;
  rfps: {
    purchased: EntityState<PurchasedRfp, string>;
  };
  selectedMatchId: string;
  selectedSingleMatch: Match;
  referRfp: EntityState<Rfp, string>;
}

const initialState: MatchesState = {
  status: 'idle',
  error: ApiErrorInitialState,
  matches: matchAdapter.getInitialState(),
  rfps: {
    purchased: purchasedRfpAdapter.getInitialState(),
  },
  selectedMatchId: '',
  selectedSingleMatch: MatchInitialState,
  referRfp: referRfpAdapter.getInitialState(),
};

export const fetchMatch = createAsyncThunk<
  Match,
  { api: PartnerApiClient; matchId: string },
  { rejectValue: ApiError }
>(
  'partner/fetchMatch',
  async (
    { api, matchId }: { api: PartnerApiClient; matchId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchMatch(matchId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchIgnoredMatches = createAsyncThunk<
  Match[],
  PartnerApiClient,
  { rejectValue: ApiError }
>(
  'partner/fetchIgnoredMatches',
  async (api: PartnerApiClient, { rejectWithValue }) => {
    try {
      return await api.fetchIgnoredMatches();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchAvailableMatches = createAsyncThunk<
  Match[],
  PartnerApiClient,
  { rejectValue: ApiError }
>(
  'partner/fetchAvailableMatches',
  async (api: PartnerApiClient, { rejectWithValue }) => {
    try {
      return await api.fetchAvailableMatches();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchPurchasedMatches = createAsyncThunk<
  Match[],
  PartnerApiClient,
  { rejectValue: ApiError }
>(
  'partner/fetchPurchasedMatches',
  async (api: PartnerApiClient, { rejectWithValue }) => {
    try {
      return await api.fetchPurchasedMatches();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const purchaseRfp = createAsyncThunk<
  PurchasedRfp,
  {
    api: PartnerApiClient;
    rfpId: string;
    matchId: string;
    token: Token | undefined;
  },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/purchaseRfp',
  async (
    {
      api,
      rfpId,
      matchId,
      token,
    }: {
      api: PartnerApiClient;
      rfpId: string;
      matchId: string;
      token: Token | undefined;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.purchaseRfp(rfpId, matchId, token);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === 'pending') return false;
    },
  },
);

export const ignoreRfp = createAsyncThunk<
  Match,
  {
    api: PartnerApiClient;
    matchId: string;
    refuseReason: string;
    refuseReasonSpecified: string;
  },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/ignore',
  async (
    {
      api,
      matchId,
      refuseReason,
      refuseReasonSpecified,
    }: {
      api: PartnerApiClient;
      matchId: string;
      refuseReason: string;
      refuseReasonSpecified: string;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.ignoreRfp(matchId, refuseReason, refuseReasonSpecified);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === 'pending') return false;
    },
  },
);

export const unignoreRfp = createAsyncThunk<
  Match,
  { api: PartnerApiClient; matchId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/unIgnore',
  async (
    { api, matchId }: { api: PartnerApiClient; matchId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.unIgnoreRfp(matchId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === 'pending') return false;
    },
  },
);

export const archiveRfp = createAsyncThunk<
  Match,
  { api: PartnerApiClient; matchId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/archive',
  async (
    { api, matchId }: { api: PartnerApiClient; matchId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.archiveRfp(matchId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === 'pending') return false;
    },
  },
);

export const unarchiveRfp = createAsyncThunk<
  Match,
  { api: PartnerApiClient; matchId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/unarchive',
  async (
    { api, matchId }: { api: PartnerApiClient; matchId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.unarchiveRfp(matchId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === 'pending') return false;
    },
  },
);

export const raiseHand = createAsyncThunk<
  string,
  { api: PartnerApiClient; params: RaiseHandParams },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/raiseHand',
  async (
    { api, params }: { api: PartnerApiClient; params: RaiseHandParams },
    { rejectWithValue },
  ) => {
    try {
      return await api.raiseHand(params);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === 'pending') return false;
    },
  },
);

export const fetchReferredRfps = createAsyncThunk<
  Rfp[],
  { api: PartnerApiClient },
  { rejectValue: ApiError }
>(
  'partner/fetchReferRfps',
  async ({ api }: { api: PartnerApiClient }, { rejectWithValue }) => {
    try {
      return await api.fetchReferredRfps();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const declareSelfWinner = createAsyncThunk<
  Match,
  { api: PartnerApiClient; matchId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/declareWinner',
  async (
    { api, matchId }: { api: PartnerApiClient; matchId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.declareWinner(matchId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === 'pending') return false;
    },
  },
);

export const setSelectedMatchId = createAction<string>(
  'partner/setSelectedMatch',
);

const matchesSlice = createSlice({
  name: 'matches',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAvailableMatches.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchAvailableMatches.fulfilled, (state, { payload }) => {
      state.matches = matchAdapter.setNewMatches(state.matches, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchAvailableMatches.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchPurchasedMatches.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchPurchasedMatches.fulfilled, (state, { payload }) => {
      state.matches = matchAdapter.setAll(state.matches, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchPurchasedMatches.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchIgnoredMatches.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchIgnoredMatches.fulfilled, (state, { payload }) => {
      state.matches = matchAdapter.setAll(state.matches, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchIgnoredMatches.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(purchaseRfp.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(purchaseRfp.fulfilled, (state, { payload }) => {
      [state.matches.new, state.matches.onGoing] =
        matchAdapter.moveNewToOnGoing(
          state.matches.new,
          state.matches.onGoing,
          payload.match_id,
        );
      state.status = 'idle';
    });
    builder.addCase(purchaseRfp.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchMatch.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchMatch.fulfilled, (state, { payload }) => {
      state.selectedSingleMatch = payload;
      state.matches = matchAdapter.setAll(state.matches, [payload]);
      state.status = 'idle';
    });
    builder.addCase(fetchMatch.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(ignoreRfp.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(ignoreRfp.fulfilled, (state, { payload }) => {
      state.status = 'idle';

      [state.matches.new, state.matches.completed] = matchAdapter.archiveMatch(
        state.matches.new,
        state.matches.completed,
        payload,
      );
      if (state.selectedSingleMatch.match_id === payload.match_id) {
        state.selectedSingleMatch = payload;
      }
    });
    builder.addCase(ignoreRfp.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;

      state.status = 'idle';
    });
    builder.addCase(unignoreRfp.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(unignoreRfp.fulfilled, (state, { payload }) => {
      state.status = 'idle';
      [state.matches.new, state.matches.completed] =
        matchAdapter.unarchiveMatch(
          state.matches.new,
          state.matches.completed,
          payload,
        );
      if (state.selectedSingleMatch.match_id === payload.match_id) {
        state.selectedSingleMatch = payload;
      }
    });
    builder.addCase(unignoreRfp.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(archiveRfp.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(archiveRfp.fulfilled, (state, { payload }) => {
      state.status = 'idle';
      [state.matches.onGoing, state.matches.completed] =
        matchAdapter.archiveMatch(
          state.matches.onGoing,
          state.matches.completed,
          payload,
        );
    });
    builder.addCase(unarchiveRfp.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(unarchiveRfp.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(unarchiveRfp.fulfilled, (state, { payload }) => {
      state.status = 'idle';
      [state.matches.onGoing, state.matches.completed] =
        matchAdapter.unarchiveMatch(
          state.matches.onGoing,
          state.matches.completed,
          payload,
        );
    });
    builder.addCase(archiveRfp.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(raiseHand.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(raiseHand.fulfilled, (state, { payload }) => {
      [state.matches.new, state.matches.onGoing] =
        matchAdapter.moveNewToOnGoing(
          state.matches.new,
          state.matches.onGoing,
          payload,
        );
      state.status = 'idle';
    });
    builder.addCase(raiseHand.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });

    builder.addCase(setSelectedMatchId, (state, { payload }) => {
      state.selectedMatchId = payload;
    });
    builder.addCase(fetchReferredRfps.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchReferredRfps.fulfilled, (state, { payload }) => {
      referRfpAdapter.setAll(state.referRfp, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchReferredRfps.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
  },
});

export const selectSelectedMatch = (state: RootState) =>
  matchAdapter.selectById(state.matches.selectedMatchId, state.matches.matches);
export const selectSelectedSingleMatch = (state: RootState) =>
  state.matches.selectedSingleMatch;

export const selectNewMatches = createSelector(
  (state: RootState) => state.matches.matches.new,
  (matches) => matchAdapter.selectAll(matches),
);

export const selectOnGoingMatches = createSelector(
  (state: RootState) => state.matches.matches.onGoing,
  (matches) => matchAdapter.selectAll(matches),
);

export const selectCompletedMatches = createSelector(
  (state: RootState) => state.matches.matches.completed,
  (matches) => matchAdapter.selectAll(matches),
);

export const selectMatchById = createSelector(
  [
    (state: RootState, _) => state.matches.matches,
    (_, matchId: string) => matchId,
  ],
  (matches, matchId) => matchAdapter.selectById(matchId, matches),
);

export const {
  selectAll: selectPurchasedRfps,
  selectById: selectPurchasedRfpById,
} = purchasedRfpAdapter.getSelectors(
  (state: RootState) => state.matches.rfps.purchased,
);

export const { selectAll: selectReferRfps, selectById: selectRefferRfpById } =
  referRfpAdapter.getSelectors((state: RootState) => state.matches.referRfp);

export const matchesReducer = matchesSlice.reducer;

export const selectClientState = (state: RootState) => state.matches;

export const selectMatchesLoading = (state: RootState) =>
  state.matches.status === 'pending';
