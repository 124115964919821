import {
  ApiError,
  ApiErrorInitialState,
  ProviderProfile,
} from '@hellodarwin/core/lib/features/entities';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/app-store';
import showErrorNotification from '../../helpers/show-error-notifications';
import PartnerApiClient from '../partner-api-client';

export interface DashboardState {
  status: 'idle' | 'pending';
  error: ApiError;
  score: ProviderProfile;
}

const initialState: DashboardState = {
  status: 'idle',
  error: ApiErrorInitialState,
  score: {} as ProviderProfile,
};

export const fetchScore = createAsyncThunk<
  ProviderProfile,
  { api: PartnerApiClient; providerId: string },
  { rejectValue: ApiError }
>(
  'partner/fetchScore',
  async (
    { api, providerId }: { api: PartnerApiClient; providerId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchProfileAnalytics(providerId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchScore.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchScore.fulfilled, (state, { payload }) => {
      state.score = payload;
      state.status = 'idle';
    });
    builder.addCase(fetchScore.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
  },
});

export const dashboardReducer = dashboardSlice.reducer;

export const selectClientState = (state: RootState) => state.score;

export const selectScoreLoading = (state: RootState) =>
  state.score.status === 'pending';
export const selectScore = (state: RootState) => state.score.score;
