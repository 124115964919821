import { MappedMatches, Match } from '@hellodarwin/core/lib/features/entities';

export default class MatchAdapter {
  setAll = (mappedMatches: MappedMatches, matches: Match[]): MappedMatches => {
    for (let match of matches) {
      switch (match.status) {
        case 'New':
          mappedMatches.new[match.match_id] = match;
          break;
        case 'Waiting':
        case 'Bought':
        case 'Finalist':
        case 'Shortlisted':
          mappedMatches.onGoing[match.match_id] = match;
          break;
        case 'Winner':
        case 'Archive':
        case 'Ignored':
        case 'Rejected':
          mappedMatches.completed[match.match_id] = match;
          break;
        default:
          mappedMatches.onGoing[match.match_id] = match;
          break;
      }
    }
    return mappedMatches;
  };

  setNewMatches = (
    mappedMatches: MappedMatches,
    matches: Match[],
  ): MappedMatches => {
    mappedMatches.new = {} as { [key: string]: Match };
    for (let match of matches) {
      mappedMatches.new[match.match_id] = match;
    }

    return mappedMatches;
  };

  selectById = (matchId: string, matches: MappedMatches): Match => {
    if (matches.new[matchId]) {
      return matches.new[matchId];
    }

    if (matches.onGoing[matchId]) {
      return matches.onGoing[matchId];
    }

    if (matches.completed[matchId]) {
      return matches.completed[matchId];
    }

    return {} as Match;
  };

  archiveMatch = (
    onGoingMatches: { [key: string]: Match },
    archivedMatches: { [key: string]: Match },
    match: Match,
  ): [
    onGoingMatches: { [key: string]: Match },
    archivedMatches: { [key: string]: Match },
  ] => {
    archivedMatches[match.match_id] = match;

    delete onGoingMatches[match.match_id];

    return [onGoingMatches, archivedMatches];
  };

  unarchiveMatch = (
    onGoingMatches: { [key: string]: Match },
    archivedMatches: { [key: string]: Match },
    match: Match,
  ): [
    onGoingMatches: { [key: string]: Match },
    completedMatches: { [key: string]: Match },
  ] => {
    onGoingMatches[match.match_id] = match;

    delete archivedMatches[match.match_id];

    return [onGoingMatches, archivedMatches];
  };

  moveNewToOnGoing = (
    newMatches: { [key: string]: Match },
    onGoingMatches: { [key: string]: Match },
    matchId: string,
  ): [
    newMatches: { [key: string]: Match },
    onGoingMatches: { [key: string]: Match },
  ] => {
    onGoingMatches[matchId] = newMatches[matchId];

    delete newMatches[matchId];

    return [newMatches, onGoingMatches];
  };

  selectAll = (matches: { [key: string]: Match }): Match[] => {
    if (matches) {
      return Object.values(matches);
    }

    return [] as Match[];
  };

  getInitialState = () => {
    return {
      new: {} as { [key: string]: Match },
      onGoing: {} as { [key: string]: Match },
      completed: {} as { [key: string]: Match },
    };
  };
}

export const createMatchAdapter = () => {
  return new MatchAdapter();
};
