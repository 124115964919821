import copyToClipboard from '@hellodarwin/core/lib/features/helpers/copy-to-clipboard';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import Attachment from '@hellodarwin/icons/dist/icons/Attachment';
import Input from 'antd/es/input/Input';
import notification from 'antd/es/notification';

import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { useAppSelector } from '../../../app/app-hooks';
import { usePartnerAnalytics } from '../../../features/analytics/use-partner-analytics';
import { selectProfile } from '../../../features/api/slices/profile-slice';

export type RfpReferralFormValues = {
  client_name: string;
  client_email: string;
  client_phone: string;
  project_category: string;
  project_budget: string;
};

const PartnerReferralShareSection = () => {
  const analytics = usePartnerAnalytics();
  const { t } = useTranslation();
  const profile = useAppSelector(selectProfile);

  const referralLink = `https://hellodarwin.com/submit-your-project?referral_code=${profile.referral_code}`;

  const handleTracking = () => {
    analytics?.referralLinkCLicked();
  };

  const handleClipboard = () => {
    copyToClipboard(referralLink);
    notification.success({
      message: 'Copied!',
      placement: 'bottomLeft',
    });
    handleTracking();
  };

  return (
    <Div flex="column" gap={20}>
      <Div flex="column" gap={8}>
        <Typography.Title level={2} elementTheme="h6">
          {t('referral|share.title')}
        </Typography.Title>
        <Typography elementTheme="body2">
          {t('referral|share.subtitle')}
        </Typography>
      </Div>

      <Div flex="row" gap={20}>
        <Input
          value={referralLink}
          contentEditable={false}
          style={{ padding: 10, textAlign: 'center' }}
        />

        <Button
          size={'medium'}
          headingIcon={<Attachment size={24} />}
          onClick={handleClipboard}
        >
          {t('button.copyLink')}
        </Button>
      </Div>
    </Div>
  );
};

export default PartnerReferralShareSection;
