import { API_URL } from '@hellodarwin/core/lib/api';
import { useLogin } from '@hellodarwin/core/lib/features/providers/login-provider';
import { useMemo } from 'react';
import PartnerApi from './partner-api';
import PartnerApiClient from './partner-api-client';

export const usePartnerApi = () => {
  const { accessToken } = useLogin();
  return useMemo(
    () => new PartnerApiClient(API_URL, accessToken),
    [accessToken],
  );
};

export const useNewPartnerApi = () => {
  const { accessToken } = useLogin();
  return useMemo(() => new PartnerApi(API_URL, accessToken), [accessToken]);
};
