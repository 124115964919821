import { Match } from '@hellodarwin/core/lib/features/entities/matches-entities';
const sortByStatus = (m1: Match, m2: Match) => {
  const map = new Map<string, number>([
    ['New', 1],
    ['Waiting', 2],
    ['Shortlisted', 3],
    ['Winner', 4],
    ['Bought', 5],
    ['Finalist', 6],
    ['Archive', 7],
    ['Rejected', 8],
    ['Ignored', 9],
  ]);
  const value1 = map.get(m1.status || '');
  const value2 = map.get(m2.status || '');

  if (!value1 || !value2) {
    return 0;
  }

  if (value1 < value2) {
    return -1;
  }
  if (value1 > value2) {
    return 1;
  }

  return 0;
};

export const sortByService = (p1: Match, p2: Match) => {
  if (!p1?.rfp?.service || !p2?.rfp?.service) return 0;
  return p1?.rfp?.service.localeCompare(p2?.rfp?.service);
};
export const sortByCreatedDate = (p1: Match, p2: Match) => {
  if (!p1?.created_at || !p2?.created_at) return 0;
  return p2.created_at > p1.created_at ? 1 : -1;
};
export const sortByUpdatedDate = (p1: Match, p2: Match) => {
  if (!p1?.updated_at || !p2?.updated_at) return 0;
  return p2.updated_at > p1.updated_at ? 1 : -1;
};

const sortByBudget = (m1: Match, m2: Match) => {
  return (m1.rfp.estimated_value || 0) - (m2.rfp.estimated_value || 0);
};

const filterMatches = (
  matches: Match[],
  sortType: string,
  orderType: string,
) => {
  let filtered = matches;
  switch (sortType) {
    case 'status':
      filtered = matches.sort(sortByStatus);
      break;
    case 'service':
      filtered = matches.sort(sortByService);
      break;
    case 'budget':
      filtered = matches.sort(sortByBudget);
      break;
    case 'recent':
      filtered = matches.sort(sortByUpdatedDate);
      break;
  }

  switch (orderType) {
    case 'ascending':
      break;
    case 'descending':
      filtered = filtered.reverse();
      break;
  }

  return filtered;
};

export default filterMatches;
