import RfpIgnoreModal from '@hellodarwin/core/lib/components/rfp/rfp-ignore-modal';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  ignoreRfp,
  selectSelectedMatch,
} from '../../../features/api/slices/matches-slice';
import { usePartnerApi } from '../../../features/api/use-partner-api';

type RfpIgnoreModalContainerProps = {
  open: boolean;
  onCancel: () => void;
};
const RfpIgnoreModalContainer = ({
  open,
  onCancel,
}: RfpIgnoreModalContainerProps) => {
  const dispatch = useAppDispatch();
  const api = usePartnerApi();
  const match = useAppSelector(selectSelectedMatch);

  const handleIgnore = async (
    match_id: string,
    ignoreReason: string,
    ignoreReasonSpecified: string,
  ) => {
    await dispatch(
      ignoreRfp({
        api,
        matchId: match_id,
        refuseReason: ignoreReason,
        refuseReasonSpecified: ignoreReasonSpecified,
      }),
    ).then(() => onCancel());
  };

  return (
    <RfpIgnoreModal
      closeModal={onCancel}
      modalVisible={open}
      handleIgnore={handleIgnore}
      match={match}
    />
  );
};

export default RfpIgnoreModalContainer;
