import { ItemType } from 'antd/es/menu/interface';
import React, { useMemo } from 'react';
import { PrioritizedRfpActions } from '../../features/enums/action-entities';
import { useTheme } from '../../plugins/styled';
import Button from '../common/button';
import Div from '../common/div';
import Dropdown from '../common/dropdown';
import Typography from '../common/typography';

type RfpActionsButtonProps = {
  actions: PrioritizedRfpActions;
  isLoading?: boolean;
  isPrimary?: boolean;
  isSecondary?: boolean;
  isBigger?: boolean;
};

const RfpActionsButton = ({
  actions,
  isPrimary,
  isSecondary,
  isLoading,
  isBigger,
}: RfpActionsButtonProps) => {
  const theme = useTheme();
  const dropdownMenuItems = useMemo(() => {
    return actions.secondaryActions.map((action) => {
      const item: ItemType = {
        label: (
          <Div flex="row" onClick={action.action} align="center" gap={10}>
            <Typography color={theme.colors.grey_2} style={{ lineHeight: 0 }}>
              {action.Icon}
            </Typography>
            <Typography elementTheme="caption" bold color={theme.colors.grey_2}>
              {action.beforeLabel}
              {action.label}
            </Typography>
          </Div>
        ),
        onClick: (e) => {
          e.domEvent.stopPropagation();
          e.domEvent.preventDefault();
          action.action;
        },
        key: action.label,
      };
      return item;
    });
  }, [actions]);

  const secondaryButtons = () => {
    return (
      actions.secondaryActions.length > 0 && (
        <Dropdown
          items={dropdownMenuItems}
          dropdownRender={(menu) =>
            React.cloneElement(menu as React.ReactElement, {
              style: { border: `1px solid ${theme.colors.primary}` },
            })
          }
          placement="bottomRight"
          cta={{ size: 20 }}
        />
      )
    );
  };
  const primaryButtons = () => {
    return (
      actions.primaryAction.length > 0 &&
      (isPrimary ? (
        <Div flex="row" style={{ gap: '4px' }}>
          {React.Children.toArray(
            actions.primaryAction.map((act, i) => (
              <Button
                onClick={act.action}
                loading={isLoading}
                size={isBigger ? 'medium' : 'extra-small'}
                fitContent
                key={act.label}
                defaultStyle={
                  i === 0 && actions.primaryAction.length > 1
                    ? theme.colors.white_1
                    : isBigger
                      ? theme.colors.yellow_1
                      : theme.colors.primary
                }
                style={{ flex: isBigger ? 'none' : 1 }}
              >
                {act.beforeLabel}
                {act.label}
              </Button>
            )),
          )}
        </Div>
      ) : (
        <Div flex="row" gap={8} align="center" fitContent>
          {React.Children.toArray(
            actions.primaryAction.map((act, index) => (
              <Button
                key={act.label}
                onClick={act.action}
                loading={isLoading}
                defaultStyle={
                  index === 1 ? theme.colors.purple_1 : theme.colors.yellow_1
                }
                style={{ flex: 1 }}
                size="extra-small"
              >
                {act.beforeLabel}
                {act.label}
              </Button>
            )),
          )}
        </Div>
      ))
    );
  };
  return (
    <>
      {isPrimary ? (
        primaryButtons()
      ) : isSecondary ? (
        secondaryButtons()
      ) : (
        <>
          {primaryButtons()}
          {secondaryButtons()}
        </>
      )}
    </>
  );
};

export default RfpActionsButton;
