import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
  Token,
} from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';

import theme from '@hellodarwin/core/lib/theme';
import { useAppSelector } from '../../app/app-hooks';

type PaymentFormProps = {
  price: number;
  handlePurchase: (token: Token | undefined) => void;
};

const CARD_OPTIONS: StripeCardElementOptions = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#262626',
      color: '#262626',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#262626',
      },
    },
    invalid: {
      iconColor: '#FF3333',
      color: '#FF3333',
    },
  },
};

const CardField = ({
  onChange,
}: {
  onChange: (event: StripeCardElementChangeEvent) => void;
}) => (
  <Div className="payment-form-row">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </Div>
);

const ErrorMessage = ({ children }: { children: string }) => (
  <Typography className="payment-form-error-message">{children}</Typography>
);

const PaymentForm = ({ price, handlePurchase }: PaymentFormProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [cardComplete, setCardComplete] = useState(false);
  const status = useAppSelector((state) => state.global.status);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (status !== 'pending') setIsLoading(false);
  }, [status]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (!cardComplete) {
      setErrorMessage(t('payment|paymentForm.validation.incompleteCard'));
      return;
    }

    setErrorMessage('');

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    if (cardElement === null) return;

    setIsLoading(true);

    const { token, error: err } = await stripe.createToken(cardElement);

    if (err !== undefined) {
      setErrorMessage(t('payment|paymentForm.validation.invalidCard'));
      return;
    }

    handlePurchase(token);
  };

  return (
    <form className="payment-form" onSubmit={handleSubmit}>
      <fieldset className="payment-form-group">
        <CardField
          onChange={(event: StripeCardElementChangeEvent) => {
            setCardComplete(event.complete);
          }}
        />
      </fieldset>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <fieldset className="payment-form-group payment-form-group-button">
        <Button
          size={'large'}
          defaultStyle={theme.colors.purple_2}
          htmlType="submit"
          loading={isLoading}
          disabled={!stripe}
          style={{ margin: 'auto' }}
        >
          {t('button.pay', { price: price })}
        </Button>
      </fieldset>
    </form>
  );
};

export default PaymentForm;
