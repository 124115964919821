import Container from '@hellodarwin/core/lib/components/common/container';
import Div from '@hellodarwin/core/lib/components/common/div';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { Match } from '@hellodarwin/core/lib/features/entities';
import {
  dollarFormat,
  getPriceInDollars,
} from '@hellodarwin/core/lib/features/helpers';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/index';
import RfpList from '../components/rfp/rfp-list';
import {
  fetchCreditBalance,
  selectCreditBalance,
} from '../features/api/slices/credits-slice';
import {
  fetchAvailableMatches,
  fetchIgnoredMatches,
  fetchPurchasedMatches,
  selectCompletedMatches,
  selectNewMatches,
  selectOnGoingMatches,
} from '../features/api/slices/matches-slice';
import { selectProfile } from '../features/api/slices/profile-slice';
import { usePartnerApi } from '../features/api/use-partner-api';

export enum OpportunitiesCategories {
  'new' = 'newOpportunities',
  'ongoing' = 'onGoingOpportunities',
  'completed' = 'completedOpportunities',
}

type OpportunitiesPageProps = {
  category: OpportunitiesCategories;
};
const OpportunitiesPage = ({ category }: OpportunitiesPageProps) => {
  const api = usePartnerApi();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const newMatches = useAppSelector(selectNewMatches);
  const onGoingMatches = useAppSelector(selectOnGoingMatches);
  const completedMatches = useAppSelector(selectCompletedMatches);

  const creditBalance = useAppSelector(selectCreditBalance);
  const balance = Math.abs(getPriceInDollars(creditBalance || 0));

  const profile = useAppSelector(selectProfile);

  const [matches, setMatches] = useState<Match[]>([]);
  const [pageTitle, setPageTitle] = useState<string>('');

  useEffect(() => {
    switch (category) {
      case OpportunitiesCategories.new:
        setMatches(newMatches);
        setPageTitle(t('common|title.newOpportunities'));
        break;
      case OpportunitiesCategories.ongoing:
        setMatches(onGoingMatches);
        setPageTitle(t('common|title.onGoingOpportunities'));
        break;
      case OpportunitiesCategories.completed:
        setMatches(completedMatches);
        setPageTitle(t('common|title.completedOpportunities'));
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, onGoingMatches, completedMatches, newMatches]);

  useEffect(() => {
    switch (category) {
      case OpportunitiesCategories.new:
        dispatch(fetchAvailableMatches(api));
        break;
      case OpportunitiesCategories.ongoing:
        dispatch(fetchPurchasedMatches(api));
        break;
      case OpportunitiesCategories.completed:
        dispatch(fetchPurchasedMatches(api));
        dispatch(fetchIgnoredMatches(api));
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    dispatch(fetchCreditBalance({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      app="provider"
      title={pageTitle}
      breadcrumbs={[
        {
          breadcrumbName: t('navigation.home'),
          path: '/',
        },
        {
          breadcrumbName: t('navigation.opportunities'),
          path: '/projects',
        },
        {
          breadcrumbName: t(`navigation.${category}`),
        },
      ]}
      actions={
        <Div flex="row" justify="flex-end">
          {
            <Typography fitContent>
              {balance > 1
                ? t('profile|profileForm.creditBalancePlural', {
                    amount: dollarFormat(balance, selectedLocale, true),
                  })
                : t('profile|profileForm.creditBalance', {
                    amount: dollarFormat(balance, selectedLocale, true),
                  })}
            </Typography>
          }
          {!!profile.available_raise_hands &&
            profile.available_raise_hands > 0 && (
              <Typography style={{ marginLeft: '1rem' }} fitContent>
                {profile.available_raise_hands > 1
                  ? t('profile|profileForm.availableHands', {
                      amount: dollarFormat(
                        profile.available_raise_hands || 0,
                        selectedLocale,
                      ),
                    })
                  : t('profile|profileForm.availableHand', {
                      amount: dollarFormat(
                        profile.available_raise_hands || 0,
                        selectedLocale,
                      ),
                    })}
              </Typography>
            )}
        </Div>
      }
    >
      <Container>
        <RfpList matches={matches} newRfp={false} />
      </Container>
    </PageLayout>
  );
};
export default OpportunitiesPage;
