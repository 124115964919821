import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';

const validateCheckbox =
  (message: string) => (_: RuleObject, value: StoreValue) => {
    // regex from https://regexr.com/3e6m0

    return !!value ? Promise.resolve() : Promise.reject(new Error(message));
  };

export default validateCheckbox;
