import Tabs from 'antd/es/tabs';
import styled from '../../../../../plugins/styled';

export const RfpMenuMobileTabs = styled(Tabs)`
  &.ant-tabs-card > .ant-tabs-nav {
    margin-bottom: 1rem;
    &::before {
      display: none;
    }
    .ant-tabs-nav-wrap {
      overflow: visible;
      &::before {
        display: none;
      }
      .ant-tabs-nav-list {
        .ant-tabs-tab + .ant-tabs-tab {
          margin: 0;
        }
      }
    }
    .ant-tabs-tab {
      border: 1px solid rgba(${(props) => props.theme.colors.grey_2}, 0.21);
      border-radius: 0;
      transition: background-color 200ms linear;
      padding: 14px;
      margin: 0;
      cursor: pointer;
      background: ${(props) => props.theme.colors.white_1};
      .ant-tabs-tab-btn {
        color: ${(props) => props.theme.colors.grey_2};
      }

      &.ant-tabs-tab-active {
        border: 1px solid ${(props) => props.theme.colors.purple_1};
        background: ${(props) => props.theme.colors.purple_1};
        .ant-tabs-tab-btn {
          color: ${(props) => props.theme.colors.white_1};
          text-shadow: none;
        }
      }
    }
  }
`;
