import { STRIPE_PUBLISHABLE_KEY } from '@hellodarwin/core/lib/api';
import AppLayout from '@hellodarwin/core/lib/components/common/layout/app-layout';
import ErrorNotFound from '@hellodarwin/core/lib/components/error/notfound';
import OnboardingSider from '@hellodarwin/core/lib/components/layout/onboarding-sider';
import useDebounceFunction from '@hellodarwin/core/lib/features/hooks/use-debounce-function';
import { useAppData } from '@hellodarwin/core/lib/features/providers/app-data-provider';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useLogin } from '@hellodarwin/core/lib/features/providers/login-provider';
import useUTM from '@hellodarwin/core/lib/features/providers/utm-provider';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { SegmentAnalytics } from '@segment/analytics.js-core';
import { loadStripe } from '@stripe/stripe-js/pure';
import notification from 'antd/es/notification';
import { useEffect, useMemo } from 'react';
import 'react-quill/dist/quill.snow.css';
import {
  Navigate,
  Navigate as NavigateRoute,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './app/app-hooks';
import Modal from './components/modal';
import {
  hello,
  onboard,
  selectProfile,
  selectProfileLoading,
  selectProviderContact,
} from './features/api/slices/profile-slice';
import { usePartnerApi } from './features/api/use-partner-api';
import Navigation from './layout/navigation';
import PageHeader from './layout/page-header';
import DashboardPage from './pages/dashboard-page';
import ExpertisePage from './pages/expertise-page';
import FaqPage from './pages/faq/partner-faq-page';
import PartnerOnBoardingPage from './pages/onboarding';
import OpportunitiesPage, {
  OpportunitiesCategories,
} from './pages/opportunities-page';
import ProfileSettingsPage from './pages/profile-settings-page';
import ProviderProfilePreviewPage from './pages/provider-profile-preview-page';
import PartnerReferralReferPage from './pages/referral/partner-referral-refer-page';
import ReferralViewPage from './pages/referral/partner-referral-view-page';
import SingleProjectPage from './pages/single-rfp-page';
import YourReviewsPage from './pages/your-reviews-page';

import './PartnerApp.scss';
import PartnerFileVaultPage from './pages/files-vault';

// load stripe once
export const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
    _hsq: any;
  }
}

const PartnerApp = () => {
  const dispatch = useAppDispatch();
  const api = usePartnerApi();

  const { changeLanguage } = useLocale();
  const appData = useAppData();
  const navigate = useNavigate();
  const { accessToken } = useLogin();
  const provider = useAppSelector(selectProfile);
  const contact = useAppSelector(selectProviderContact);
  const { t } = useTranslation('common', { useSuspense: false });
  const { pathname, hash, key } = useLocation();
  const isLoggedIn = useMemo(() => !!provider.provider_id, [provider]);

  const isHelloLoading = useAppSelector((state) =>
    selectProfileLoading(state, 'hello'),
  );

  const isAppLoading = useMemo(
    () => isHelloLoading || !isLoggedIn,
    [isHelloLoading, isLoggedIn],
  );

  const utmParams = useUTM();

  const fetchProvider = useDebounceFunction(() => {
    const urlParams = new URLSearchParams(window.location.search);
    dispatch(
      hello({ api, sb_promocode: urlParams.get('promocode'), utm: utmParams }),
    );
  }, 500);

  useEffect(() => {
    if (accessToken === '') return;
    fetchProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if (!!contact?.preferred_language) {
      changeLanguage(contact?.preferred_language);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact?.preferred_language]);

  useEffect(() => {
    if (contact?.email) {
      try {
        var _hsq = (window._hsq = window._hsq || []);
        _hsq?.push([
          'identify',
          {
            email: contact.email,
          },
        ]);
      } catch (e: any) {
        console.error(e);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact?.email]);

  //GOTO HASH

  useEffect(() => {
    // if a hash link, scroll to id
    if (!(hash === '' && window !== undefined)) {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 300);
    }
  }, [pathname, hash, key]);

  // APP-291
  // TODO: Should remove once returning provider are all re-onboarded
  const updateOnboardedAt = async () => {
    await dispatch(onboard({ api }));
  };

  useEffect(() => {
    const migrationDate = Date.parse('2021-07-25 08:00:00');
    if (Date.parse(provider.onboarded_at!) < migrationDate) {
      updateOnboardedAt();
      navigate('/expertise');
      notification.destroy();
      notification.warning({
        message: t('common:message.updateExpertise'),
        placement: 'bottomLeft',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider.onboarded_at]);

  const needOnBoarding = useMemo(() => {
    if (!!provider) {
      return !provider.onboarded_at;
    }
    return false;
  }, [provider]);

  useEffect(() => {
    const { setHeader, setNav } = appData;
    if (needOnBoarding) {
      setHeader(<PageHeader isOnboarding />);
      setNav(<OnboardingSider appType="provider" />);
    } else {
      setHeader(<PageHeader />);
      setNav(<Navigation />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needOnBoarding, pathname]);

  return (
    <>
      <AppLayout isLoading={isAppLoading}>
        {needOnBoarding ? (
          <Routes>
            <Route path="*" element={<PartnerOnBoardingPage />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<NavigateRoute to="/dashboard" />} />
            <Route
              path="/projects"
              element={<NavigateRoute to="/projects/new" />}
            />
            <Route
              path="/projects/new"
              element={
                <OpportunitiesPage category={OpportunitiesCategories.new} />
              }
            />
            <Route
              path="/projects/available"
              element={
                <OpportunitiesPage category={OpportunitiesCategories.new} />
              }
            />
            <Route
              path="/projects/ongoing"
              element={
                <OpportunitiesPage category={OpportunitiesCategories.ongoing} />
              }
            />
            <Route
              path="/projects/closed"
              element={
                <OpportunitiesPage
                  category={OpportunitiesCategories.completed}
                />
              }
            />
            <Route
              path="/projects/:matchId"
              element={<SingleProjectPage defaultTab="project" />}
            />
            <Route
              path="/projects/:matchId/client"
              element={<SingleProjectPage defaultTab="client" />}
            />
            <Route
              path="/profile"
              element={<NavigateRoute to="/profile/edit" />}
            />
            <Route
              path="/profile/edit"
              element={<ProviderProfilePreviewPage defaultState="edit" />}
            />
            <Route
              path="/profile/website"
              element={<ProviderProfilePreviewPage defaultState="website" />}
            />
            <Route
              path="/profile/client"
              element={<ProviderProfilePreviewPage defaultState="client" />}
            />
            <Route
              path="/profile/preview"
              element={<NavigateRoute to="/profile" />}
            />
            <Route
              path="/profile/settings"
              element={<Navigate to={'/settings'} />}
            />
            <Route path="/settings" element={<ProfileSettingsPage />} />
            <Route
              path="/settings/contact"
              element={<ProfileSettingsPage defaultActiveKey="contact" />}
            />
            <Route
              path="/settings/company"
              element={<ProfileSettingsPage defaultActiveKey="company" />}
            />
            <Route
              path="/settings/users"
              element={<ProfileSettingsPage defaultActiveKey="users" />}
            />
            <Route
              path="/referral"
              element={<NavigateRoute to="/referral/myreferrals" />}
            />
            <Route
              path="/referral/refer"
              element={<PartnerReferralReferPage />}
            />
            <Route
              path="/referral/share"
              element={<NavigateRoute to="/referral/refer" />}
            />
            <Route path="/reviews" element={<YourReviewsPage />} />
            <Route
              path="/yourReviews"
              element={<NavigateRoute to={'/reviews'} />}
            />
            <Route path="/badges" element={<YourReviewsPage isBadge />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route
              path="/referral/myreferrals"
              element={<ReferralViewPage />}
            />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/expertise" element={<ExpertisePage />} />
            <Route path="/files-vault" element={<PartnerFileVaultPage />} />
            <Route
              path="/files-vault/:folderId"
              element={<PartnerFileVaultPage />}
            />
            <Route path="*" element={<ErrorNotFound />} />
          </Routes>
        )}
        <Modal />
      </AppLayout>
    </>
  );
};

export default PartnerApp;
