import {
  ApiError,
  ApiErrorInitialState,
  ReviewResult,
} from '@hellodarwin/core/lib/features/entities';
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app';
import PartnerApiClient from '../partner-api-client';

const reviewsAdapter = createEntityAdapter<ReviewResult, string>({
  selectId: (review: ReviewResult) => review.review_id,
});

export interface clientState {
  status: 'idle' | 'pending';
  error: ApiError;
  reviews: EntityState<ReviewResult, string>;
}

const initialState: clientState = {
  status: 'idle',
  error: ApiErrorInitialState,
  reviews: reviewsAdapter.getInitialState(),
};

export const fetchReviews = createAsyncThunk<
  ReviewResult[],
  { api: PartnerApiClient },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/fetchReviews',
  async ({ api }: { api: PartnerApiClient }, { rejectWithValue }) => {
    try {
      return await api.fetchReviews();
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReviews.fulfilled, (state, { payload }) => {
      if (payload !== null) {
        reviewsAdapter.addMany(state.reviews, payload);
      }
      state.status = 'idle';
    });
    builder.addCase(fetchReviews.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchReviews.pending, (state) => {
      state.status = 'pending';
    });
  },
});

export const { selectAll: selectAllReviews, selectById: selectReview } =
  reviewsAdapter.getSelectors((state: RootState) => state.reviews.reviews);

export const selectReviewsLoading = (state: RootState) =>
  state.reviews.status === 'pending';

export const reviewsReducer = reviewsSlice.reducer;
