import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import getAgenciesPage from '@hellodarwin/core/lib/features/helpers/get-agencies-page';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import Copy from '@hellodarwin/icons/dist/icons/Copy';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import Select from 'antd/es/select';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../app';
import { selectProfile } from '../../features/api/slices/profile-slice';

interface YourReviewLinkProps {
  hideLangSelector?: boolean;
  dir?: 'row' | 'column';
}
const YourReviewLink = ({
  hideLangSelector,
  dir = 'row',
}: YourReviewLinkProps) => {
  const { t } = useTranslation();
  const profile = useAppSelector(selectProfile);
  const [language, setLanguage] = useState(profile.profile_language);
  const [reviewsUrl, setReviewsUrl] = useState('');
  const { getFullLanguage } = useTranslations();

  useEffect(() => {
    (async () => {
      if (language)
        await getAgenciesPage({
          provider: profile,
          withReviews: true,
          locale: language,
        }).then((page) => setReviewsUrl(page));
    })();
  }, [profile, language]);

  const handleCopyClick = () => {
    if (profile.name) {
      navigator.clipboard.writeText(reviewsUrl);
    }
  };

  const [messageApi] = message.useMessage();

  const successcopy = () => {
    handleCopyClick();
    messageApi.open({
      type: 'success',
      content: t('common|message.clipboard'),
    });
  };

  return (
    <Div flex={dir} align="center" gap={16} tablet={{ flex: 'column' }}>
      <Input
        contentEditable={false}
        style={{
          width: 350,
          maxWidth: '100%',
        }}
        value={profile.name ? reviewsUrl : ''}
      />
      {!hideLangSelector && (
        <Select
          placeholder="Select option"
          value={language}
          onSelect={setLanguage}
        >
          <Select.Option value={'en'}>{getFullLanguage('en')}</Select.Option>
          <Select.Option value={'fr'}>{getFullLanguage('fr')}</Select.Option>
        </Select>
      )}
      <Button
        headingIcon={<Copy size={24} />}
        size="small"
        children={t('common|button.copy')}
        onClick={successcopy}
      />
    </Div>
  );
};

export default YourReviewLink;
